/*import React from "react";

const HospitalSectionTwo = () => {
  return (
    <div className="container" style={{background:"red"}}>

        <div style={{background:"blue", marginTop:"-15%"}}><p className="subtext">That’s why</p></div>
      
      <div>
      <h1 className="main-text" style={{background:"blue"}}>
        Toughest work on Earth is <br />
        <span className="highlight">Managing your Hospital</span>
      </h1>
      </div>
      
    </div>
  );
};

export default HospitalSectionTwo;

const styles = `

.container {
  display: flex;
  height:10px;
  flex-direction: column;
  background-color: black;
  color: white;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.subtext {
  font-size: 1.5rem;
  font-weight: 600;
}

.main-text {
  font-size: 5rem;
  font-weight: 700;
  
}

.highlight {
  color: #ff3b77;
  font-weight: 800;
}

@media (max-width: 768px) {

   .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}
  .main-text {
    font-size: 1.5rem;
  }

  .subtext {
    font-size: 1.2rem;
  }
}
`;

const styleTag = document.createElement("style");
styleTag.innerHTML = styles;
document.head.appendChild(styleTag);
*/

import React from "react";



const HospitalSectionTwo = () => {
  return (
    <div className="sectiontwocontainer" style={{background:"black"}}>
      <style>
        {`
          /* Main Container */
          .sectiontwocontainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            padding: 50px 20px;
            min-height: 50vh; /* Reduced height */
          }

          /* Subtext with Fade-in Animation */
          .subtext {
            font-size: 1.5rem;
            font-weight: 600;
            opacity: 0;
            animation: fadeIn 2s ease-in-out forwards;
          }

          /* Main Heading with Pop-in Effect */
          .main-text {
            font-size: 5rem;
            font-weight: 700;
            margin-top: 10px;
            opacity: 0;
            transform: scale(0);
            animation: popIn 0.6s ease-in-out forwards 1s;
          }

          /* Highlighted Text */
          .highlighted {
            color:rgb(3, 255, 41);
            font-weight: 800;
          }

          /* Animations */
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes popIn {
            from { opacity: 0; transform: scale(0); }
            to { opacity: 1; transform: scale(1); }
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .sectiontwocontainer {
              min-height: 30vh; /* Reduced height on mobile */
              padding: 20px;
            }
            .main-text {
              font-size: 2.5rem;
              margin-top:5%;
            }
            .subtext {
              font-size: 1.2rem;
            }
          }
        `}
      </style>

      {/* Subtext */}
      <div className="subtext"></div>

      {/* Main Heading */}
      <h1 className="main-text">
        Toughest work on Earth is <br />
        <span className="highlighted">Managing your Hospital</span>
      </h1>
    </div>
  );
};

export default HospitalSectionTwo;


