import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components'; // Install with: npm install styled-components
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';

       
const questions = [
    "How long does the registration process take?",
    "What documents are required for registration?",
    "Can I apply online?",
  ];
  
  const answers = [
    "The processing time varies, but it typically takes 2-3 months",
    "See the list above for the required documents.",
    "Check the official website for the latest online application options.",
  ];
  
const MadhyaPradeshRegistration = () => {
  return (
    <div style={{background:"black"}}>
      <div style={{ height: "5%" }}></div>
    <Container >
      <Helmet>
        <title>Permanent Medical Registration Madhya Pradesh: Step-by-Step Guide</title>
        <meta
          name="description"
          content="Learn how to apply for permanent medical registration in Madhya Pradesh. Get detailed steps, required documents, and official links. Simplify your MP medical council registration."
        />
        <meta
          name="keywords"
          content="permanent medical registration Madhya Pradesh, MP medical council registration, medical registration in Madhya Pradesh, how to register as a doctor in MP, doctors registration MP, Madhya Pradesh medical license"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-permanent-registration-madhya-pradesh" />
      </Helmet>

      
      <Main>
        <Section>
          <H1>Permanent Medical Registration Madhya Pradesh: A Comprehensive Guide</H1>

          <H2 style={{color: "#ff007f", marginTop:"5%" }}>Why Permanent Medical Registration in Madhya Pradesh is Essential?</H2>
          <P>
            Permanent registration with the Madhya Pradesh Medical Council is mandatory for all medical practitioners in the state. It validates your qualifications and ensures you can legally practice medicine. This is crucial for maintaining professional integrity and providing quality healthcare services to the public.
          </P>

          <H2 style={{color: "#ff007f", marginTop:"5%"  }}>What are the Consequences of Not Registering?</H2>
          <P>
            The primary reason for obtaining permanent medical registration is to legally practice medicine in Madhya Pradesh. Without it, you are not authorized to practice. Practicing medicine without registration can lead to legal penalties, including fines and potential legal action. Furthermore, it undermines the trust and confidence of patients and the medical community. It also hinders your ability to work in established hospitals and clinics in MP.
          </P>

          <H2 style={{color: "#ff007f", marginTop:"5%"  }}>Who Needs to Apply for Permanent Medical Registration?</H2>
          <P>
            All medical graduates (MBBS, MD, MS, DNB, Diploma, DM, McH) who intend to practice medicine in Madhya Pradesh must apply for permanent registration with the Madhya Pradesh Medical Council. This includes newly graduated doctors, those relocating to MP from other states, and foreign medical graduates who have obtained necessary clearances.
          </P>

          <H2 style={{color: "#ff007f"}}>How to Apply for MP Medical Council Registration: A Step-by-Step Guide</H2>

          <H3 style={{color: "#FFFF00"}}>1. Gather Required Documents</H3>
          <UL style={{marginBottom:"5%"}}>
            <LI>Original and attested copies of your MBBS/MD/MS degree certificates.</LI>
            <LI>Internship completion certificate.</LI>
            <LI>Proof of identity (Aadhaar card, passport, etc.).</LI>
            <LI>Proof of address.</LI>
            <LI>Passport-sized photographs.</LI>
            <LI>No objection certificate (NOC) if applicable.</LI>
            <LI>Registration fee payment receipt.</LI>
          </UL>

          <H3 style={{marginBottom:"5%",color: "#FFFF00"}}>2. Visit the Madhya Pradesh Medical Council Website</H3>
          

          <H3 style={{color: "#FFFF00"}}>3. Fill the Application Form</H3>
          <P style={{marginBottom:"5%"}}>
            Download the application form or fill it online as per the council’s instructions. Ensure all information is accurate and complete.
          </P>

          <H3 style={{color: "#FFFF00"}}>4. Submit Documents and Pay Fees</H3>
          <P style={{marginBottom:"5%"}}>
            Submit the required documents along with the application form. Pay the registration fees as specified by the council.
          </P>

          <H3 style={{color: "#FFFF00"}}>5. Verification Process</H3>
          <P style={{marginBottom:"5%"}}>
            The council will verify your documents. This process may take some time.
          </P>

          <H3 style={{color: "#FFFF00"}}>6. Receive Your Registration Certificate</H3>
          <P style={{marginBottom:"5%"}}>
            Once the verification is complete, you will receive your permanent registration certificate.
          </P>

          <H2 style={{color: "#ff007f", fontWeight:"bold" }}>Important Links and Resources</H2>
          <UL>
            <LI><A href="[Official MP Medical Council Link]" target="_blank" rel="noopener noreferrer">Official Madhya Pradesh Medical Council Website</A></LI>
            <LI><A href="[Link to registration form]" target="_blank" rel="noopener noreferrer">Download Registration Form</A></LI>
            <LI><A href="[Link to fee structure]" target="_blank" rel="noopener noreferrer">Fee Structure</A></LI>
            <LI>Madhya Pradesh Medical Council Address: F-7, Sanchi Complex Opposite Board Office, Bhopal (M.P.) - 462016 [INDIA]</LI>
            <LI>Madhya Praesh Medical Council Phone Number: 0755 - 2767786</LI>
            <LI>Madhya Pradesh Medical Council Fax: 0755 - 2551568 </LI>
            <LI>Madhya Pradesh Medical Council Email: registrarmpmc@gmail.com (or) registrar.mpmc@mp.gov.in</LI> 
          </UL>

           </Section>
      </Main>
    </Container>


    
    <FAQFunction questions={questions} answers={answers}/> 

    <div style={{marginLeft:"5%", marginRight:"5%", marginTop:"5%"}}>
      <p style={{fontStyle:"italic", font:"11px", color:"lightgray"}}>
      Disclaimer:
      </p>
      <p style={{fontStyle:"italic", font:"9px", color:"lightgray"}}>
    The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.</p>
   
    </div>
     <FooterNext/>
    </div>
  );
};

// Styled Components
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
  
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top:45px
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 100;
  margin-top:45px
`;

const H2 = styled.h2`
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 200;
`;

const H3 = styled.h3`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 35px;
  
`;

const P = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 35px;
 
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 15px;
`;

const LI = styled.li`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 8px;
`;

const A = styled.a`
  color: #007bff;
  text-decoration: none;
`;

export default MadhyaPradeshRegistration;
