import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';

const questions = [
    "How long does the registration process take?",
    "What documents are required for registration?",
    "Can I apply online?",
  ];
  
  const answers = [
    "The stipulated time is 7 working days, but it may vary.",
    "Refer to the list above for mandatory documents.",
    "Yes, the application process is primarily online through the ARTPS portal.",
  ];
  
const AssamRegistration = () => {
  return (
    <div style={{background:"black"}}>
      <div style={{ height: "5%" }}></div>
    <Container>
      <Helmet>
        <title>Permanent Medical Registration Assam: Step-by-Step Guide</title>
        <meta
          name="description"
          content="Learn how to apply for permanent medical registration in Assam. Get detailed steps, required documents, and official links. Simplify your Assam medical council registration."
        />
        <meta
          name="keywords"
          content="permanent medical registration Assam, Assam medical council registration, medical registration in Assam, how to register as a doctor in Assam, doctors registration Assam, Assam medical license"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-permanent-registration-assam" />
      </Helmet>

      <Main>
        <Section>
          <H1>Permanent Medical Registration Assam: A Comprehensive Guide</H1>

          <H2 style={{color: "#ff007f", marginTop:"5%" }}>Why is Permanent Medical Registration in Assam Important?</H2>
          <P>
            Just like you need a driving license to drive, permanent medical registration is mandatory for practicing medicine or attending academic courses in Assam.
          </P>

          <H2 style={{color: "#ff007f", marginTop:"5%" }}>What Happens if You Don't Register?</H2>
          
            <P>Illegal Practice – Practicing medicine without registration is punishable by law.
            </P>
              

          <H2 style={{color: "#ff007f", marginTop:"5%" }}>Who Needs to Apply?</H2>
          <P>
            Any doctor intending to practice medicine or participate in academic courses in Assam must register. This includes graduates from Assam, other Indian states, and abroad.
          </P>

          <H2 style={{color: "#ff007f"}}>Step-by-Step Online Registration Process</H2>
          <H3 style={{color: "#FFFF00"}}>1. Online Registration on ARTPS Portal</H3>
          <UL>
            <LI>Visit the ARTPS portal.</LI>
            <LI>Click "Register" and enter details like name, mobile number, and email.</LI>
            <LI>Login using your credentials.</LI>
          </UL>
          
          <H3 style={{color: "#FFFF00"}}>2. Apply for Permanent Medical Registration</H3>
          <UL>
            <LI>Click "Apply Online" and select "Permanent Medical Registration".</LI>
            <LI>Read the instructions and click "Apply".</LI>
          </UL>
          
          <H3 style={{color: "#FFFF00"}}>3. Upload Required Documents</H3>
          <UL>
            <LI>Passport-size photo (.JPEG, max size: 1MB).</LI>
            <LI>Class 10 Admit Card/Birth Certificate.</LI>
            <LI>Internship Certificate.</LI>
            <LI>MBBS Pass Certificate.</LI>
            <LI>Provisional/Permanent Registration Certificate from the medical council.</LI>
          </UL>
          
          <H3 style={{color: "#FFFF00"}}>4. Submit and Track Application</H3>
          <UL>
            <LI>Submit the form and note down the reference number.</LI>
            <LI>Track your application status via the "Track" button.</LI>
          </UL>

          <H3 style={{color: "#FFFF00"}}>5. Physical Inspection</H3>
          <P>If approved, you'll be notified about the physical inspection date.</P>

          <H3 style={{color: "#FFFF00"}}>6. Fee Payment</H3>
          <P>
            Registration fee: Rs. 5000 + GST 18% + Service Charges. Payment notification will be sent via SMS and email.
          </P>

          <H3 style={{color: "#FFFF00"}}>7. Certificate Download</H3>
          <P>After successful payment, download your certificate from the ARTPS portal.</P>

          <H2 style={{color: "#ff007f", fontWeight:"bold" }}>Important Links and Resources</H2>
          <UL>
            <LI><A href="https://acmr.in/registration/" target="_blank" rel="noopener noreferrer">Official Assam Medical Council Website</A></LI>
            <LI><A href="https://sewasetu.assam.gov.in/site/service-apply/permanent-registration-of-mbbs-doctors#:~:text=After%20successful%20submission%20of%20documents,it%20from%20the%20ARTPS%20portal." target="_blank" rel="noopener noreferrer">ARTPS Registration Portal</A></LI>
          </UL>
        </Section>
      </Main>
    </Container>
    
    
    <FAQFunction questions={questions} answers={answers}/> 

    <div style={{marginLeft:"5%", marginRight:"5%", marginTop:"5%"}}>
      <p style={{fontStyle:"italic", font:"11px", color:"lightgray"}}>
      Disclaimer:
      </p>
      <p style={{fontStyle:"italic", font:"9px", color:"lightgray"}}>
    The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.</p>
   
    </div>
    <FooterNext/>
    </div>
  );
};

const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top:45px;
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 100;
  margin-top:45px;
`;

const H2 = styled.h2`
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 200;
`;

const H3 = styled.h3`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 35px;
`;

const P = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 35px;
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 15px;
`;

const LI = styled.li`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 8px;
`;

const A = styled.a`
  color: #007bff;
  text-decoration: none;
`;

export default AssamRegistration;
