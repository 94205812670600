import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';

const questions = [
    "How long does the registration process take?",
    "What documents are required for registration?",
    "Can I apply online?",
  ];
  
const answers = [
    "The processing time varies, but it typically takes 2-3 months.",
    "See the list above for the required documents.",
    "Check the official website for the latest online application options.",
  ];
  
const APMCRegistration = () => {
  return (
    <div style={{background:"black"}}>
      <div style={{ height: "5%" }}></div>
      <Container>
        <Helmet>
          <title>APMC Permanent Registration: Step-by-Step Guide</title>
          <meta
            name="description"
            content="Learn how to apply for permanent medical registration with the Andhra Pradesh Medical Council (APMC). Get detailed steps, required documents, and official links."
          />
          <meta
            name="keywords"
            content="APMC registration, Andhra Pradesh Medical Council, doctor registration AP, medical license AP, permanent registration APMC"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>

        <Main>
          <Section>
            <H1>APMC Permanent Registration: Why & How to Apply</H1>

            <H2>Why You MUST Get Your APMC Permanent Registration</H2>
            <P>
              If you're a doctor in Andhra Pradesh, getting your permanent registration with the APMC is not optional. It's a must-do for these reasons:
            </P>
            <UL>
              <LI>It's the Law: You can't legally practice medicine in AP without it.</LI>
              <LI>Shows You're Qualified: It proves you're a real, trusted doctor.</LI>
              <LI>Helps Your Career: Hospitals and clinics want doctors with this registration.</LI>
              <LI>Needed for Government Jobs: You need it to work in government healthcare.</LI>
              <LI>For Training: You often can't join important training courses without it.</LI>
            </UL>

            <H2>What Happens If You Don't Get It?</H2>
            <UL>
              <LI>Fines and Legal Problems: You could get fined or even go to court.</LI>
              <LI>Stopped from Practicing: The APMC can stop you from working as a doctor in AP.</LI>
              <LI>Missed Job Opportunities: You won't be able to work in good hospitals.</LI>
              <LI>Bad Reputation: People won't trust you as a doctor.</LI>
            </UL>

            <H2>How to Apply for APMC Permanent Registration</H2>
            <H3>1. Get Your Documents Ready</H3>
            <UL>
              <LI>Original degree certificates.</LI>
              <LI>Internship certificate.</LI>
              <LI>ID proof (Aadhaar, passport, etc.).</LI>
              <LI>Address proof.</LI>
              <LI>Photos.</LI>
              <LI>Registration fee payment receipt.</LI>
            </UL>

            <H3>2. Fill Out the Form</H3>
            <P>Download the form from the APMC website, fill it carefully, and double-check details.</P>

            <H3>3. Submit Your Application</H3>
            <P>Submit the form and documents at the APMC office, pay the fee, and collect your receipt.</P>

            <H3>4. Wait for Verification</H3>
            <P>The APMC will check your documents and may ask you for an interview.</P>

            <H3>5. Keep Checking</H3>
            <P>Track your application status on the APMC website or by calling the office.</P>

            <H2>Important Tips</H2>
            <UL>
              <LI>Check the official APMC website for the latest info.</LI>
              <LI>Use original documents and keep copies.</LI>
              <LI>Be patient; processing can take time.</LI>
              <LI>Visit the APMC office if you have any doubts.</LI>
            </UL>
          </Section>
        </Main>
      </Container>

      <FAQFunction questions={questions} answers={answers} />

      <div style={{marginLeft:"5%", marginRight:"5%", marginTop:"5%"}}>
      <p style={{fontStyle:"italic", font:"11px", color:"lightgray"}}>
      Disclaimer:
      </p>
      <p style={{fontStyle:"italic", font:"9px", color:"lightgray"}}>
    The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.</p>
   
    </div>
    
      <FooterNext />
    </div>
  );
};

// Styled Components
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top: 45px;
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 100;
  margin-top: 45px;
`;

const H2 = styled.h2`
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 200;
  color: #ff007f;
`;

const H3 = styled.h3`
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #FFFF00;
`;

const P = styled.p`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 15px;
`;

const LI = styled.li`
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 8px;
`;

export default APMCRegistration;
