import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';

const questions = [
    "How long does the registration process take?",
    "What documents are required for registration?",
    "Can I apply online?",
];
  
const answers = [
    "The processing time varies, but it typically takes a few weeks to months, depending on verification.",
    "See the list above for the required documents.",
    "Check the official CGMC website for online application options.",
];
  
const ChhattisgarhRegistration = () => {
  return (
    <div style={{background:"black"}}>
      <div style={{ height: "5%" }}></div>
      <Container>
        <Helmet>
          <title>Permanent Medical Registration Chhattisgarh: Step-by-Step Guide</title>
          <meta
            name="description"
            content="Learn how to apply for permanent medical registration in Chhattisgarh. Get detailed steps, required documents, and official links. Simplify your CGMC registration."
          />
          <meta
            name="keywords"
            content="permanent medical registration Chhattisgarh, CG medical council registration, medical registration in Chhattisgarh, how to register as a doctor in CG, doctors registration CG, Chhattisgarh medical license"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-permanent-registration-chhattisgarh" />
        </Helmet>

        <Main>
          <Section>
            <H1>Permanent Medical Registration Chhattisgarh: A Simple Guide</H1>
            
            <H2 style={{color: "#ff007f", marginTop:"5%" }}>Why Do You Need CGMC Registration?</H2>
            <P>
              <UL>
                <LI><b>Legal Requirement:</b> It's mandatory to practice medicine legally in Chhattisgarh.</LI>
                <LI><b>Professional Validation:</b> Confirms your qualifications and credibility.</LI>
                <LI><b>Essential for Employment:</b> Hospitals and clinics require proof of CGMC registration.</LI>
              </UL>
            </P>

            <H2 style={{color: "#ff007f", marginTop:"5%" }}>What If You Don't Register?</H2>
            <P>
              <UL>
                <LI><b>Illegal Practice:</b> You'll be practicing illegally, leading to legal consequences.</LI>
                <LI><b>Damaged Reputation:</b> Your professional standing will be compromised.</LI>
                <LI><b>Limited Job Prospects:</b> Significant barriers to employment.</LI>
              </UL>
            </P>

            <H2 style={{color: "#ff007f", marginTop:"5%" }}>Who Needs to Register?</H2>
            <P>
              <UL>
                <LI>All doctors intending to practice in Chhattisgarh.</LI>
                <LI>Graduates from medical colleges within and outside Chhattisgarh.</LI>
                <LI>Each doctor is responsible for their own registration.</LI>
              </UL>
            </P>

            <H2 style={{color: "#ff007f"}}>How to Apply for CGMC Registration: Step-by-Step Guide</H2>
            
            <H3 style={{color: "#FFFF00"}}>1. Gather Required Documents</H3>
            <UL>
              <LI>High School and H.S.S. mark sheets & certificates.</LI>
              <LI>Aadhaar card & caste certificate.</LI>
              <LI>All MBBS examination mark sheets.</LI>
              <LI>Internship completion certificate.</LI>
              <LI>Original provisional registration certificate.</LI>
              <LI>MBBS degree/provisional degree.</LI>
              <LI>3 recent passport-size photographs.</LI>
              <LI>Bond copy and other related documents if applicable.</LI>
            </UL>
            
            <H3 style={{color: "#FFFF00"}}>2. Obtain the Application Form</H3>
            <P>Download the "Permanent Registration Form" in PDF format from the official CGMC website.</P>

            <H3 style={{color: "#FFFF00"}}>3. Prepare Your Application</H3>
            <P>Attach a passport-sized photograph (attested by the Dean of your medical college) to the form.</P>

            <H3 style={{color: "#FFFF00"}}>4. Submit Your Application</H3>
            <P>Submit the form along with required documents and attested photocopies to the CGMC office.</P>

            <H3 style={{color: "#FFFF00"}}>5. Pay the Registration Fee</H3>
            <P>Payment is done via Bank Draft in favor of "Registrar, Chhattisgarh Medical Council, Raipur (C.G.)." Check CGMC website for the latest fee structure.</P>

            <H3 style={{color: "#FFFF00"}}>6. Verification and Certification</H3>
            <P>CGMC will verify documents and issue a permanent registration certificate upon approval.</P>

            <H2 style={{color: "#ff007f", fontWeight:"bold" }}>Important Links and Resources</H2>
            <UL>
              <LI><A href="[CGMC Official Website]" target="_blank" rel="noopener noreferrer">Official CGMC Website</A></LI>
              <LI><A href="[Registration Form Link]" target="_blank" rel="noopener noreferrer">Download Registration Form</A></LI>
              <LI>CGMC Address: Near Ayurvedic College, Raipur, Chhattisgarh.</LI>
              <LI>CGMC Phone Number: [Insert Number]</LI>
              <LI>CGMC Email: [Insert Email]</LI>
            </UL>
          </Section>
        </Main>
      </Container>
      
      <FAQFunction questions={questions} answers={answers}/>

      <div style={{marginLeft:"5%", marginRight:"5%", marginTop:"5%"}}>
      <p style={{fontStyle:"italic", font:"11px", color:"lightgray"}}>
      Disclaimer:
      </p>
      <p style={{fontStyle:"italic", font:"9px", color:"lightgray"}}>
    The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.</p>
   
    </div>
   
   
      <FooterNext/>
    </div>
  );
};

// Styled Components
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top:45px;
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 100;
`;

const H2 = styled.h2`
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: 200;
`;

const H3 = styled.h3`
  font-size: 25px;
  margin-bottom: 35px;
`;

const P = styled.p`
  font-size: 20px;
  line-height: 1.6;
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
`;

const LI = styled.li`
  font-size: 20px;
  line-height: 1.6;
`;

const A = styled.a`
  color: #007bff;
  text-decoration: none;
`;

export default ChhattisgarhRegistration;
