import React from "react";

import toxicseniorimg from "../images/toxicsenior.png";

const ToxicSeniorsSection = () => {
  return (
    <div className="med-container">
      <style>
        {`
          /* Main Container */
          .med-container {
            display: flex;
            background-color: black;
            color: white;
            padding: 1px;
            font-family: 'Poppins', sans-serif;
          }

          /* Left Image Section */
          .med-image {
            width: 45%;
            padding-left:5%;
          }

          /* Right Text Section */
          .med-text-container {
            width: 60%;
            padding-left: 1px;
            margin-top:5%;
            margin-left:2%;

          }

          /* Heading */
          .med-heading {
            font-size: 2.8rem;
            font-weight: 700;
            margin-bottom: 10px;
          }

          .med-highlight {
            color: #00ff00; /* Green */
          }

          /* Bullet Points */
          .med-list {
            font-size: 1.2rem;
            line-height: 1.8;
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .med-container {
              flex-direction: column;
              padding: 20px;
              align-items: center;
            justify-content: center;
            }

            .med-image {
              width: 100%;
              max-width: 425px;
              margin-left:1%;
            }

            .med-text-container {
              width: 100%;
              padding: 0;
              margin-top: 0px;
            }

            .med-heading {
              font-size: 2rem;
              text-align: center;
            }

            .med-list {
              font-size: 1rem;
              text-align: left;
              margin-right:25px;
            }
          }
        `}
      </style>

      {/* Left Image */}

      
      <img 
        src={toxicseniorimg}
        alt="Toxic Seniors Illustration" 
        className="med-image" 
        
      />
      

      {/* Right Text Section */}
      <div className="med-text-container">
        <h1 className="med-heading">
          How to deal with <span className="med-highlight">Toxic Seniors</span>?
        </h1>

        <ul className="med-list" style={{marginRight:"10%"}}>
          <li>Medical college ka safar waise hi tough hota hai, upar se agar toxic seniors mil jayein toh aur bhi mushkil ho jata hai. Chahe woh unnecessary ragging, extra kaam thopna ya bas attitude dikhana ho – you don’t have to suffer silently!</li>
<li></li>
<li>As a Dr Planete Student Member, you’ll get:</li>
<li>✅ Smart tips & tricks toxic seniors ko handle karne ke liye</li>
<li>✅ Senior doctors ki guidance jo khud yeh sab face kar chuke hain</li>
             </ul>
      </div>
    </div>
  );
};

export default ToxicSeniorsSection;
