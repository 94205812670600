import React, { useState } from "react";

const FAQFunction = ({ questions, answers }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <style>
        {`
          .faq-container {
            background-color: #000;
            color: #fff;
            font-family: Poppins, sans-serif;
            padding: 70px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 1200px;
            margin: auto;
          }

          .faq-title-container {
            width: 35%;
            text-align: left;
            margin-bottom: 20px;
            margin-top:25px
          }

          .faq-title {
            font-size: 40px;
            font-weight: bold;
          }

          .faq-title-pink {
            color: #ff007f;
          }

          .faq-list {
            width: 60%;
          }

          .faq-item {
            border-bottom: 1px solid #333;
            padding: 15px 0;
            cursor: pointer;
          }

          .faq-question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            color: white;
            font-weight: 100;
            font-size: 30px;
          }

          .faq-toggle {
            font-weight: bold;
            font-size: 20px;
          }

          .faq-answer {
            
            padding: 10px 15px;
            margin-top: 10px;
            border-radius: 5px;
            font-size: 25px;
            color: #ff007f;
          }

          /* Mobile Responsiveness */
          @media (max-width: 1024px) {
            .faq-container {
              flex-direction: column;
              padding: 50px 20px;
            }

            .faq-title-container {
              width: 100%;
              text-align: center;
              margin-bottom: 30px;
            }

            .faq-list {
              width: 100%;
            }

            .faq-question {
            
            font-size: 20px;
          }

          .faq-toggle {
            font-weight: bold;
            font-size: 20px;
          }

          .faq-answer {
            font-size: 15px;
          }
          }
        `}
      </style>
      <div className="faq-container">
        <div className="faq-title-container">
          <h2 className="faq-title">Frequently</h2>
          <h2 className="faq-title faq-title-pink">Asked Questions</h2>
        </div>
        <div className="faq-list">
          {questions.map((question, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{question}</span>
                <span className="faq-toggle">{openIndex === index ? "−" : "+"}</span>
              </div>
              {openIndex === index && <div className="faq-answer">{answers[index]}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQFunction;

/*import React, { useState } from "react";

const FAQFunction = ({ questions, answers }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <style>
        {`
          .faq-container {
            background-color: #000;
            color: #fff;
            font-family: Poppins, sans-serif;
            padding: 70px;
            display: flex;
            flex-wrap: wrap;
          }

          .faq-title-container {
            width: 90%;
            text-align: left;
            margin-bottom: 20px;
          }

          .faq-title {
            font-size: 40px;
            font-weight: bold;
            width: 90%;
            text-align: left;
          }

          .faq-list {
            width: 100%;
          }

          .faq-item {
            border-bottom: 1px solid #333;
            padding: 15px 0;
            cursor: pointer;
          }

          .faq-question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            color: #39FF14;
            font-weight: 600;
            font-size: 16px;
          }

          .faq-toggle {
            color: #ff007f;
            font-weight: bold;
            font-size: 20px;
          }

          .faq-answer {
            background-color: #111;
            padding: 10px 15px;
            margin-top: 10px;
            border-radius: 5px;
            font-size: 14px;
          }

          @media (min-width: 768px) {
            .faq-container {
              flex-direction: row;
            }

            .faq-title-container {
              width: 30%;
            }

            .faq-list {
              width: 70%;
            }
          }
        `}
      </style>
      <div className="faq-container">
        <div className="faq-title-container">
          <h2 className="faq-title">Frequently</h2>
          <h2 className="faq-title" style={{color:"#ff007f"}}>Asked Questions</h2>
        </div>
        <div className="faq-list">
          {questions.map((question, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{question}</span>
                <span className="faq-toggle">{openIndex === index ? "−" : "+"}</span>
              </div>
              {openIndex === index && <div className="faq-answer">{answers[index]}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQFunction;*/