import React from "react";
import { cheq, storage, db } from "../firebaseConfig";
import firebase from "firebase";
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import FooterNext from "../Footers/FooterNext";
import { Form, Button, Container } from 'react-bootstrap';

function SupportForm() {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    query: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitDetails = async (e) => {
    e.preventDefault();

    const { name, phoneNumber, email, query } = formData;

    try {
      if (!name || !phoneNumber || !email || !query) {
        alert("Please fill in all fields");
        return;
      }

      // Example: You can directly add the data to Firestore
      await db.collection('userQueries').add({
        name,
        phone: phoneNumber,
        email,
        query,
        timestamp: new Date(),
      });

      // Show success message
      alert("Your query has been registered");
    } catch (error) {
      console.error('Error submitting query:', error);
      // Handle the error appropriately, e.g., show an error message
      alert("Error submitting query. Please try again later.");
    }

    // Optionally, you can redirect or perform additional actions here
  };

  return (

    <div>
    <Container className="mt-5">
      <h1>Contact & Support</h1>
      <p>If you wish to delete your account, please include the request in the 'Write Your Query' section below. Our support team will process your request promptly. Please note that account deletion is irreversible, and you will lose access to your account and associated data </p>
      <Form onSubmit={submitDetails}>
        <Form.Group>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number:</Form.Label>
          <Form.Control
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Write Your Query:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="query"
            value={formData.query}
            onChange={handleChange}
          />
        </Form.Group>
        <Button  style={{ marginTop: "35px", marginBottom: "5px" }} type="submit">Submit</Button>
      </Form>
    </Container>
    </div>
  );
}

export default SupportForm;
