import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';
import InformativeArticlesDisclaimer from '../../informativeArticlesDisclaimer';

const questions = [
    "How long does the registration process take?",
    "Why do I need to register with the Gujarat Medical Council?",
    "What happens if I practice medicine in Gujarat without GMC registration?",
    "Who needs to register with the GMC?",
    "Where do I start the registration process?",
    "Is the entire registration process online?",
    "What documents do I need to submit?",
    "Can I submit photocopies of my documents?",
    "How much is the registration fee?",
    "How do I pay the registration fee?",
    "How long does the registration process take?",
    "What if I have a delay in applying for permanent registration?",
    "What if my name has changed since my medical degree was issued?",
    "What is the process for doctors who graduated from medical colleges outside of the Gujarat state?",
    "What documents are accepted as proof of birth date?",
    "What documents are accepted as photo ID?",
    "Do my passport-size photographs need to be attested?",
    "How can I contact the Gujarat Medical Council?",
    "What documents are required for registration?",
    "Can I apply online?",
  ];
  
const answers = [
    "The processing time varies, but it typically takes 20-25 days.",
    "It's legally required to practice medicine in Gujarat. It validates your qualifications and is essential for employment.",
    "You'll be practicing illegally, which can lead to legal penalties and damage your professional reputation.",
    "All doctors who intend to practice medicine in Gujarat must register, regardless of where they obtained their medical degree.",
    "Begin by registering an account on www.gmconline.co.in.",
    "Yes, the initial application is online, but you will need to print, sign, and submit physical copies of documents.",
    "The required documents vary slightly depending on whether you graduated from a Gujarat State institution or an institution outside Gujarat. Refer to the official website for a complete list.",
    "Yes, you must submit documents as specified on the website.",
    "The registration fee is Rs. 1800/-.",
    "Payment is made online through the portal.",
    "The certificate will be sent by Registered Post to your provided address within 20-25 days.",
    "You may need to submit a delay affidavit and pay a late fee.",
    "You'll need to provide evidence of the name change, such as a Gazette, Marriage Registration Certificate, or Affidavit.",
    "In addition to the standard documents, you'll need to provide your State Medical Council Registration and a No Objection Certificate (NOC) from your State Medical Council.",
    "School Leaving Certificate, S.S.C. Certificate, H.S.C. Mark sheet (with birth date), or Birth Certificate.",
    "Aadhar Card, Driving License, Passport, or Pan Card.",
    "Yes, they must be signed by the applicant on the front.",
    "Refer to the official Gujarat Medical Council website for contact information.",
    "See the list above for the required documents.",
    "Yes, the application process starts online at gmconline.co.in."
  ];
  
    
  const GujaratMedicalRegistration = () => {
    return (
      <div style={{background:"black"}}>
        <div style={{ height: "5%" }}></div>
      <Container>
        <Helmet>
          <title>Gujarat Medical Council Registration: Step-by-Step Guide</title>
          <meta
            name="description"
            content="Learn how to apply for permanent medical registration in Gujarat. Get detailed steps, required documents, and official links."
          />
          <meta
            name="keywords"
            content="Gujarat medical council registration, medical registration in Gujarat, how to register as a doctor in Gujarat, doctors registration Gujarat, Gujarat medical license"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-gujarat-medical-registration" />
        </Helmet>
  
        <Main>
          <Section>
            <H1>Your Guide to Practicing Medicine in Gujarat: Getting Your GMC Registration</H1>
  
            <H2 style={{color: "#ff007f", marginTop:"5%" }}>Why is GMC Registration Important?</H2>
            <UL>
              <LI><strong>Legal Requirement:</strong> It's mandatory to practice medicine legally in Gujarat.</LI>
              <LI><strong>Professional Credibility:</strong> It validates your qualifications and builds trust with patients and colleagues.</LI>
              <LI><strong>Employment Requirement:</strong> Hospitals and clinics in Gujarat require proof of GMC registration.</LI>
            </UL>
  
            <H2 style={{color: "#ff007f", marginTop:"5%" }}>What Happens if You Don't Register?</H2>
            <UL>
              <LI><strong>Illegal Practice:</strong> Practicing medicine without registration is a legal offense and can lead to penalties.</LI>
              <LI><strong>Loss of Professional Standing:</strong> Your reputation will suffer, and you'll face difficulties in your career.</LI>
              <LI><strong>Loss of Opportunities:</strong> You'll be unable to secure employment or practice in any healthcare facilities.</LI>
            </UL>
  
            <H2 style={{color: "#ff007f", marginTop:"5%" }}>Who Needs to Register?</H2>
            <P>All doctors intending to practice medicine in Gujarat, regardless of where they obtained their medical degree.</P>
  
            <H2 style={{color: "#ff007f", marginTop:"5%" }}>How to Apply for Permanent Registration with the GMC:</H2>
            
            <H3 style={{color: "#FFFF00", marginTop:"5%" }}>For MBBS Graduates of Gujarat State Institutions:</H3>
            <UL>
              <LI>Create an Account: Register a user account on <a href="https://www.gmconline.co.in" target="_blank" rel="noopener noreferrer">www.gmconline.co.in</a>.</LI>
              <LI>Online Application: Fill in all details on the online portal.</LI>
              <LI>Print and Sign: Bring a printed, physically signed copy of the application form.</LI>
              <LI>Appointment Details: Bring a printout of your appointment details, including the Payment Transaction ID number.</LI>
              <LI>Submit necessary documents, including Proof of Birth Date, Photo ID, Internship/Passing Certificate, and MBBS Degree Certificate.</LI>
              <LI>Photographs: Provide three color passport-size photographs, signed on the front.</LI>
              <LI>Payment: Pay Rs. 1800/- online.</LI>
              <LI>Certificate Delivery: The certificate will be sent by Registered Post within 20-25 days.</LI>
            </UL>
            
            <H3 style={{color: "#FFFF00", marginTop:"5%" }}>For MBBS Graduates from Outside Gujarat State Institutions:</H3>
            <UL>
              <LI>Follow the same initial steps as above.</LI>
              <LI>Provide additional documents, including MBBS Mark Sheets, State Medical Council Registration, and No Objection Certificate.</LI>
              <LI>Payment: Make a total payment of Rs. 1800/- through online payment.</LI>
              <LI>Certificate Delivery: The certificate will be sent by Registered Post within 20-25 days.</LI>
            </UL>
  
            <H2 style={{color: "#ff007f", marginTop:"5%" }}>Important Notes:</H2>
            <UL>
              <LI>Always refer to the official Gujarat Medical Council website for accurate and up-to-date information.</LI>
              <LI>Ensure all copies are attested as required.</LI>
              <LI>Be prepared for potential processing times.</LI>
            </UL>
          </Section>
        </Main>

        </Container>
        <FAQFunction questions={questions} answers={answers}/> 

        <InformativeArticlesDisclaimer/>
        <FooterNext/>
      </div>
    );
  };
  
  // Styled Components
  const Container = styled.div`
    background-color: #000;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    text-align: left;
    min-height: 100vh;
  `;
  
  const Main = styled.main`
    max-width: 85%;
    margin: 0 auto;
  `;
  
  const Section = styled.section`
    margin-bottom: 30px;
    margin-top: 45px;
  `;
  
  const H1 = styled.h1`
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 100;
    margin-top: 45px;
  `;
  
  const H2 = styled.h2`
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 200;
  `;
  
  const H3 = styled.h3`
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 35px;
  `;
  
  const P = styled.p`
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 35px;
  `;
  
  const UL = styled.ul`
    list-style-type: disc;
    padding-left: 25px;
    margin-bottom: 15px;
  `;
  
  const LI = styled.li`
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 8px;
  `;
  
  export default GujaratMedicalRegistration;
  