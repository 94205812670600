import React, { useState } from "react";
import {cheq, db, database} from "../../firebaseConfig";
import firebase from "firebase";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from "react-bootstrap";
import EligibleSpecialists from "./eligibleSpecialist";
import GetApp from "../../Footers/getApp";
import FooterNew from "../../Footers/Footer";
import dayjs from "dayjs";
import FooterNext from "../../Footers/FooterNext";

import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';


export default function Observership({ }) {

    
    const [courseTitle, setCourseTitle] = useState("");
    const [courseDuration, setCourseDuration] = useState("");
    const [courseStartingDate, setCourseStartingDate] = useState(new Date());
    const [courseCertificate, setCourseCertificate] = useState("");

    const [courseFees, setCourseFee] = useState("");
    const [termsnconditions, setTermsNConditions] = useState("");
    const [refundPolicy, setRefundPolicy] = useState("");

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const x1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x2 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x3 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x4 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x5 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x6 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x7 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x8 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x9 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x10 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x11 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x12 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x14 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x15 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x16 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x17 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x18 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x19 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x20 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x21 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x22 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x23 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x24 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x25 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x26 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x27 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x28 = { inputProps: { 'aria-label': 'Checkbox demo' } };


    const [boolX1, setX1] = useState("0");
    const checkX1 = () => {
        setX1(1 - boolX1);
    }

    const [boolX2, setX2] = useState("0");
    const checkX2 = () => {
        setX2(1 - boolX2);
    }

    const [boolX3, setX3] = useState("0");
    const checkX3 = () => {
        setX3(1 - boolX3);
    }

    const [boolX4, setX4] = useState("0");
    const checkX4 = () => {
        setX4(1 - boolX4);
    }

    const [boolX5, setX5] = useState("0");
    const checkX5 = () => {
        setX5(1 - boolX5);
    }

    const [boolX6, setX6] = useState("0");
    const checkX6 = () => {
        setX6(1 - boolX6);
    }

    const [boolX7, setX7] = useState("0");
    const checkX7 = () => {
        setX7(1 - boolX7);
    }

    const [boolX8, setX8] = useState("0");
    const checkX8 = () => {
        setX8(1 - boolX8);
    }

    const [boolX9, setX9] = useState("0");
    const checkX9 = () => {
        setX9(1 - boolX9);
    }

    const [boolX10, setX10] = useState("0");
    const checkX10 = () => {
        setX10(1 - boolX10);
    }

    const [boolX11, setX11] = useState("0");
    const checkX11 = () => {
        setX11(1 - boolX11);
    }

    const [boolX12, setX12] = useState("0");
    const checkX12 = () => {
        setX12(1 - boolX12);
    }

   

    const [boolX14, setX14] = useState("0");
    const checkX14 = () => {
        setX14(1 - boolX14);
    }

    const [boolX15, setX15] = useState("0");
    const checkX15 = () => {
        setX15(1 - boolX15);
    }

    const [boolX16, setX16] = useState("0");
    const checkX16 = () => {
        setX16(1 - boolX16);
    }

    const [boolX17, setX17] = useState("0");
    const checkX17 = () => {
        setX17(1 - boolX17);
    }

    const [boolX18, setX18] = useState("0");
    const checkX18 = () => {
        setX18(1 - boolX18);
    }

    const [boolX19, setX19] = useState("0");
    const checkX19 = () => {
        setX19(1 - boolX19);
    }

    const [boolX20, setX20] = useState("0");
    const checkX20 = () => {
        setX20(1 - boolX20);
    }

    const [boolX21, setX21] = useState("0");
    const checkX21 = () => {
        setX21(1 - boolX21);
    }

    const [boolX22, setX22] = useState("0");
    const checkX22 = () => {
        setX22(1 - boolX22);
    }

    const [boolX23, setX23] = useState("0");
    const checkX23 = () => {
        setX23(1 - boolX23);
    }

    const [boolX24, setX24] = useState("0");
    const checkX24 = () => {
        setX24(1 - boolX24);
    }

    const [boolX25, setX25] = useState("0");
    const checkX25 = () => {
        setX25(1 - boolX25);
    }

    const [boolX26, setX26] = useState("0");
    const checkX26 = () => {
        setX26(1 - boolX26);
    }

    const [boolX27, setX27] = useState("0");
    const checkX27 = () => {
        setX27(1 - boolX27);
    }
    const [boolX28, setX28] = useState("0");
    const checkX28 = () => {
        setX28(1 - boolX28);
    }

    const [boolmbbsStudent, setBoolMbbsStudent] = useState("0");
    const checkIsMbbsStudent = () => {
        setBoolMbbsStudent(1 - boolmbbsStudent);
    }

    const [boolPGStudent, setBoolPGStudent] = useState("0");
    const checkIsPGStudent = () => {
        setBoolPGStudent(1 - boolPGStudent);
    }

    const [boolSSStudent, setBoolSSStudent] = useState("0");
    const checkIsSSStudent = () => {
        setBoolSSStudent(1 - boolSSStudent);
    }

    

    function PlusPremiumObservershipPlan() {


        if (courseTitle === "") {
            alert("Please enter Course Title");
            return;
        }
        if(courseDuration === ""){
            alert("Please enter Course Duration");
            return;
        }
        if(courseFees === ""){
            alert("Please enter Course Fee");
            return;
        }
        if(courseStartingDate===""){
            alert("Please enter Course Starting Date");
            return;
        }

        if(courseCertificate === ""){
            alert("Please enter Course Certificate");
            return;
        }

        if (termsnconditions === "" || refundPolicy === "") {
            alert("Please enter tNc");
            return;
        }

        var x = new Array();
        var teach = new Array();

        if(boolmbbsStudent == true){
            teach.push("MBBS Student");
        }if(boolPGStudent == true){
            teach.push("PG Student/Aspirant");
        }if(boolSSStudent == true){
            teach.push("SS Student/Aspirant");
        }

        if(boolX1 == true){
            x.push("Anesthesia");
        }if(boolX2 == true){
            x.push("Cardiology");
        }if(boolX3 == true){
            x.push("Cardiothoracic Surgery");
        }if(boolX4 == true){
            x.push("Cosmetic & Plastic Surgery");
        }if(boolX5 == true){
            x.push("Dermatology");
        }if(boolX6 == true){
            x.push("Dentistry");
        }if(boolX7 == true){
            x.push("Endocrinology");
        }if(boolX8 == true){
            x.push("ENT Surgery");
        }if(boolX9 == true){
            x.push("Gastroenterology");
        }if(boolX10 == true){
            x.push("General Medicine");
        }if(boolX11 == true){
            x.push("General Surgery");
        }if(boolX12 == true){
            x.push("Gynecology");
        }if(boolX14 == true){
            x.push("Intensive Medicine");
        }if(boolX15 == true){
            x.push("Nephrology");
        }if(boolX16 == true){
            x.push("Neurology");
        }if(boolX17 == true){
            x.push("Neurosurgery");
        }if(boolX18 == true){
            x.push("Oncology");
        }if(boolX19 == true){
            x.push("Ophthalmology");
        }if(boolX20 == true){
            x.push("Orthopedics");
        }if(boolX21 == true){
            x.push("Pathology");
        }if(boolX22 == true){
            x.push("Pediatric Medicine");
        }if(boolX23 == true){
            x.push("Pediatric Surgery");
        }if(boolX24 == true){
            x.push("Radiology");
        }if(boolX25 == true){
            x.push("Pulmonology");
        }if (boolX26 == true) {
            x.push("Rheumatology");
        } if (boolX27 == true) {
            x.push("Urology");
        } if (boolX28 == true) {
            x.push("Others");
        }
    

        db.collection(`observershipcourses`).doc(cheq.currentUser.phoneNumber).collection("request").add({

            Plan: "Premium",
            PaymentStatus: "Pending",
            courseTitle: courseTitle,
            courseDuration: courseDuration,
            courseFees: courseFees,
            courseStartingDate: dayjs(courseStartingDate).format('LL'),
            courseCertificate: courseCertificate,

            courseTnC: termsnconditions,
            courseRefundPolicy: refundPolicy,

            department: x,
            eligible: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        });

       
        database.ref(`Hospital/${cheq.currentUser.phoneNumber}/observershipcourses`).push({
            
            Plan: "Premium",
            PaymentStatus: "Pending",
           courseTitle: courseTitle,
           courseDuration: courseDuration,
           courseFees: courseFees,
           courseStartingDate: dayjs(courseStartingDate).format('LL'),
           courseCertificate: courseCertificate,

           courseTnC: termsnconditions,
           courseRefundPolicy: refundPolicy,

           department: x,
           eligible: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);

        alert("Proceeding to Payment!!")
        window.location.replace('https://pages.razorpay.com/drplanete-premium-obsvership');

    }


    function PlusFreeObservershipPlan() {


        if (courseTitle === "") {
            alert("Please enter Course Title");
            return;
        }
        if(courseDuration === ""){
            alert("Please enter Course Duration");
            return;
        }
        if(courseFees === ""){
            alert("Please enter Course Fee");
            return;
        }
        if(courseStartingDate===""){
            alert("Please enter Course Starting Date");
            return;
        }

        if(courseCertificate === ""){
            alert("Please enter Course Certificate");
            return;
        }

        if (termsnconditions === "" || refundPolicy === "") {
            alert("Please enter tNc");
            return;
        }

        var x = new Array();
        var teach = new Array();

        if(boolmbbsStudent == true){
            teach.push("MBBS Student");
        }if(boolPGStudent == true){
            teach.push("PG Student/Aspirant");
        }if(boolSSStudent == true){
            teach.push("SS Student/Aspirant");
        }

        if(boolX1 == true){
            x.push("Anesthesia");
        }if(boolX2 == true){
            x.push("Cardiology");
        }if(boolX3 == true){
            x.push("Cardiothoracic Surgery");
        }if(boolX4 == true){
            x.push("Cosmetic & Plastic Surgery");
        }if(boolX5 == true){
            x.push("Dermatology");
        }if(boolX6 == true){
            x.push("Dentistry");
        }if(boolX7 == true){
            x.push("Endocrinology");
        }if(boolX8 == true){
            x.push("ENT Surgery");
        }if(boolX9 == true){
            x.push("Gastroenterology");
        }if(boolX10 == true){
            x.push("General Medicine");
        }if(boolX11 == true){
            x.push("General Surgery");
        }if(boolX12 == true){
            x.push("Gynecology");
        }if(boolX14 == true){
            x.push("Intensive Medicine");
        }if(boolX15 == true){
            x.push("Nephrology");
        }if(boolX16 == true){
            x.push("Neurology");
        }if(boolX17 == true){
            x.push("Neurosurgery");
        }if(boolX18 == true){
            x.push("Oncology");
        }if(boolX19 == true){
            x.push("Ophthalmology");
        }if(boolX20 == true){
            x.push("Orthopedics");
        }if(boolX21 == true){
            x.push("Pathology");
        }if(boolX22 == true){
            x.push("Pediatric Medicine");
        }if(boolX23 == true){
            x.push("Pediatric Surgery");
        }if(boolX24 == true){
            x.push("Radiology");
        }if(boolX25 == true){
            x.push("Pulmonology");
        }if (boolX26 == true) {
            x.push("Rheumatology");
        } if (boolX27 == true) {
            x.push("Urology");
        } if (boolX28 == true) {
            x.push("Others");
        }
    

        db.collection(`observershipcourses`).doc(cheq.currentUser.phoneNumber).collection("request").add({

            Plan: "Free",
            courseTitle: courseTitle,
            courseDuration: courseDuration,
            courseFees: courseFees,
            courseStartingDate: dayjs(courseStartingDate).format('LL'),
            courseCertificate: courseCertificate,

            courseTnC: termsnconditions,
            courseRefundPolicy: refundPolicy,

            department: x,
            eligible: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);

       
        database.ref(`Hospital/${cheq.currentUser.phoneNumber}/observershipcourses`).push({
            
            Plan: "Free",
           courseTitle: courseTitle,
           courseDuration: courseDuration,
           courseFees: courseFees,
           courseStartingDate: dayjs(courseStartingDate).format('LL'),
           courseCertificate: courseCertificate,

           courseTnC: termsnconditions,
           courseRefundPolicy: refundPolicy,

           department: x,
           eligible: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);

        alert("Course Added Successfully!!")
        window.location.replace('/drplanete-dashboard');

       
    }

    return (
        <div >
            <div class="container body-content border-1 border-indigo-600" style={{ marginTop: "1px", marginBottom: "35px" }}>

            <div>
                            <center>
                                <h3>Hi, Please enter details about your Observership Plan</h3>
                            </center>
                        </div>

                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Observership Plan Title" variant="outlined" 
                                onChange={(e) => setCourseTitle(e.target.value)}/>
                        </div>

                        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic" fullWidth 
                            label="Duration" variant="outlined" 
                            onChange={(e) => setCourseDuration(e.target.value)}/>
                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="Starting Date"
                                    fullWidth
                                    style={{ width: "1000px" }}
                                    value={courseStartingDate}
                                    onChange={(newValue) => {
                                        setCourseStartingDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>


                        <div class="container body-content border-1 border-indigo-600" style={{marginTop:"15px"}}>
                <div><center><h3 style={{ marginTop: "15px" }}> Department</h3></center></div>
                <div style={{ marginTop: "15px" }}>
                    <div className="column"><Checkbox {...x1} onChange={checkX1}/>Anesthesia</div>
                    <div className="column"><Checkbox {...x2} onChange={checkX2} />Cardiology</div>
                    <div className="column"><Checkbox {...x3} onChange={checkX3} />Cardiothoracic Surgery</div>
                    <div className="column"><Checkbox {...x4} onChange={checkX4} />Cosmetic & Plastic Surgery</div>
                    <div className="column"><Checkbox {...x5} onChange={checkX5} />Dermatology</div>
                    <div className="column"><Checkbox {...x6} onChange={checkX6}/>Dentistry</div>
                    <div className="column"><Checkbox {...x7} onChange={checkX7}/>Endocrinology</div>
                    <div className="column"><Checkbox {...x8} onChange={checkX8}/>ENT Surgery</div>
                    <div className="column"><Checkbox {...x9} onChange={checkX9}/>Gastroenterology</div>
                    <div className="column"><Checkbox {...x10} onChange={checkX10}/>General Medicine</div>
                    <div className="column"><Checkbox {...x11} onChange={checkX11}/>General Surgery</div>
                    <div className="column"><Checkbox {...x12} onChange={checkX12}/>Gynecology</div>
                    <div className="column"><Checkbox {...x14} onChange={checkX14}/>Intensive Medicine</div>
                    <div className="column"><Checkbox {...x15} onChange={checkX15}/>Nephrology</div>
                    <div className="column"><Checkbox {...x16} onChange={checkX16}/>Neurology</div>
                    <div className="column"><Checkbox {...x17} onChange={checkX17}/>Neurosurgery</div>
                    <div className="column"><Checkbox {...x18} onChange={checkX18}/>Oncology</div>
                    <div className="column"><Checkbox {...x19} onChange={checkX19}/>Ophthalmology</div>
                    <div className="column"><Checkbox {...x20} onChange={checkX20}/>Orthopedics</div>
                    <div className="column"><Checkbox {...x21} onChange={checkX21}/>Pathology</div>
                    <div className="column"><Checkbox {...x22} onChange={checkX22}/>Pediatric Medicine</div>
                    <div className="column"><Checkbox {...x23} onChange={checkX23}/>Pediatric Surgery</div>
                    <div className="column"><Checkbox {...x24} onChange={checkX24}/>Radiology</div>
                    <div className="column"><Checkbox {...x25} onChange={checkX25}/>Pulmonology</div>
                    <div className="column"><Checkbox {...x26} onChange={checkX26}/>Rheumatology</div>
                    <div className="column"><Checkbox {...x27} onChange={checkX27}/>Urology</div>
                    <div className="column"><Checkbox {...x28} onChange={checkX28}/>Others</div>

                </div>
            </div>
            
                        <div class="container body-content border-1 border-indigo-600" style={{ marginTop: "400px", marginBottom: "15px" }}>
                            <div style={{marginLeft:"35%", marginRight:"25%"}}>
                            <h3>Eligibility Criteria</h3>
                            </div>
                               
                               
                                <div style={{ marginTop: "15px" }}>

                                    <div className="column"><Checkbox {...label} onChange={checkIsMbbsStudent}/>MBBS Student</div>
                                    <div className="column"><Checkbox {...label} onChange={checkIsPGStudent}/>PG Student/Aspirant</div>
                                    <div className="column"><Checkbox {...label} onChange={checkIsSSStudent}/>Practicing Doctor / SS Aspirant</div>
                                </div>
                            
                        </div>



                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic" 
                            fullWidth 
                            label="Observership Certificate" 
                            variant="outlined" 
                            onChange={(e) => setCourseCertificate(e.target.value)}/>
                        </div>
                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Observership Fee" 
                                variant="outlined"
                                onChange={(e) => setCourseFee(e.target.value)} />
                        </div>
                        
                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Terms & Conditions" 
                                variant="outlined"
                                onChange={(e) => setTermsNConditions(e.target.value)} />
                        </div>

                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Refund Policy" 
                                variant="outlined"
                                onChange={(e) => setRefundPolicy(e.target.value)} />
                        </div>



                        {/*<div>
                            <center>
                                <Button variant="primary" size="lg" style={{ width: "150px", marginBottom: "25px" }} onClick={PlusNewTrainingCourse}>Save</Button>
                            </center>
                                </div>*/}
                                 <div>
                        <p>By listing your Observership Plan, you agree to Dr Planete's Terms & Conditions and Privacy Policy</p>
                    </div>

<div>
                    <PricingTable highlightColor='#1976D2'>
                        <PricingSlot title='FREE'>
                            <PricingDetail> <b style={{ fontSize: "35px" }}>20%</b> <p style={{ fontSize: "15px" }}>Observership Fee/Per Enrollment</p></PricingDetail>
                            <PricingDetail> <p>Promotions Charges as per Prevailing Rates</p> </PricingDetail>
                            <PricingDetail> <p>Monthly Payouts</p> </PricingDetail>
                            
                             
                            <center>
                                <Button size="lg" variant="secondary" onClick={PlusFreeObservershipPlan}>Try for Free</Button>
                            </center>
                        </PricingSlot>
                        
                    
                        <PricingSlot highlighted buttonText='' title='PREMIUM' priceText="@ just ₹ 11999/per year (incl. all taxes)">
                            <PricingDetail> <b style={{ fontSize: "40px", color: "blue" ,fontFamily:"Quicksand"}}>9%</b>  <p style={{ fontSize: "15px" }}>Observership Fee/Per Enrollment</p></PricingDetail>
                            <PricingDetail> <b style={{ fontSize: "35px", color: "red", fontFamily: "cursive" }}>Save 11%</b>  <p style={{ fontSize: "15px" }}><b>On every Enrollment</b></p><p style={{ marginTop: "-5px" }}>(Compared to Free Plan)</p> </PricingDetail>
                            <PricingDetail> <b>1 Year Free Promotions on Dr Planete</b> </PricingDetail>
                            <PricingDetail> <b>Weekly Payouts</b></PricingDetail>

                            <center>
                                <Button size="lg" variant="success" onClick={PlusPremiumObservershipPlan}>Get Premium</Button>
                                </center>

                        </PricingSlot>
                        


                    </PricingTable>
                </div>
                    </div>
                    <div><FooterNext/></div>
        </div>
    );
}