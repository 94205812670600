import React from 'react';
import {cheq} from './firebaseConfig';
import {useAuthState} from 'react-firebase-hooks/auth';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import "./index.css";
import Doctor from './doctor/Doctor.js';
import About from "./About";
import Error from "./Error";
import Hospital from "./hospital/Hospital";
import DoctorRegistration from './doctor/doctorreg.js';
import OnTop from './Headers/OnTop';


import MedicalStudent from "./medicalstudent/MedicalStudent";

import DashboardDoctor from "./doctor/dashboardDoctor";
import Safeplan from "./school_corporate/safeplan.js";
import DoctorRegistrationPortal from "./doctor/doctorRegistrationPortal";
import Termsandcondtions from "./termsandconditions/termsandconditions";
import PrivacyPolicy from "./termsandconditions/privacypolicy";

import Story from "./company/Story.js";
import Careers from "./company/Careers.js";
import Contact from "./company/Contact.js";
import Team from "./company/Team.js";
import Todo from "./components/base/Todo";
import Offers from "./medicalstudent/Offers";
import CampusAmbassadorProgram from "./medicalstudent/campusAmbassadorProgram";
import ThirtyDayQuestion from "./medicalstudent/thirtydayquestion";
import ExamAlerts from "./medicalstudent/examalerts";

import Vaccination from "./school_corporate/plans/Vaccination";
import EducationSession from "./school_corporate/plans/EducationSession";
import RequestVisitingDoctor from "./school_corporate/plans/RequestVisitingDoctor";
import DentalCheckup from "./school_corporate/plans/DentalCheckup";
import StudentHealthCheckup from "./school_corporate/plans/StudentHealthCheckup";
import EmployeeHealthCheckup from "./school_corporate/plans/EmployeeHealthCheckup";
import EyeCheckup from "./school_corporate/plans/EyeCheckup";
import AffiliateRegistration from "./medicalstudent/affiliateRegistration";
import TrainingCentre from "./hospital/registration/trainingCentre";
import { Height, Train } from "@material-ui/icons";

import CourseDetails from "./hospital/registration/courseDetails";
import Observership from "./hospital/registration/observership";
import ViewCourses from "./hospital/viewCourses";
import ViewObservershipPlans from "./hospital/viewObservershipPlans";
import IncreasePractice from "./doctor/content/increasePractice";
import AboutMRCP from "./doctor/content/aboutMRCP";
import AboutMRCS from "./doctor/content/aboutMRCS";
import AboutFMGE from "./doctor/content/aboutFMGE";
import AboutNEETPG from "./doctor/content/aboutNEETPG.js";
import AboutUSMLE from "./doctor/content/aboutUSMLE";
import AboutNEETSS from "./doctor/content/aboutNEETSS";
import GrowPlusPricing from "./products/pricing";
import GrowPlus from "./products/growplus";
import GroPlusTnc from "./termsandconditions/groplusterms";
import DoctorRegistrationBasicDetails from "./doctor/registration/basicDetails";
import DoctorRegistrationOtherDetails from "./doctor/registration/superSpecializationDetails";
import DoctorRegistrationSuperSpecialization from "./doctor/registration/superSpecializationDetails";
import DoctorRegistrationSpecialization from "./doctor/registration/specializationDetails";
import NavNext from './Headers/Nav/NavNext';
import BasicExample from './Headers/Nav/BasicExample';
import NewHomePage from './club/newHomePage.js';




function App() {
  const logout = () => {
    cheq.signOut();
  };

  const [user] = useAuthState(cheq);
  return(

    <div>

      <OnTop/>
      <NavNext/>
     
    
    </div>
  
  );
};
  
export default App;

{/*import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './App.css';
import Header from './Header';
import Home from './Home';
import Login from './Login'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {cheq} from "./firebase";
import {useAuthState} from 'react-firebase-hooks/auth';
import { getAuth, RecaptchaVerifier } from "firebase/auth";


function App() {
  const[user] = useAuthState(cheq);

  return(
    user ? <Home/> : <Login/>
  );
}


export default App;*/}




{/*<div>
      <div><OnTop/></div>
    <div><NavNext/></div>
    {/*<div><NavNew/></div>*/}

  {/*</div>*/}