import { React, Component } from "react";
import FooterNew from "../Footers/Footer.js";
import Footer from "../Footers/Footer.js";
import FooterNext from "../Footers/FooterNext.js";
import SupportForm from "../support/support.js";


function Contact () {

  {
    return (
      <div style={{background:"black"}}>

        
        <div>
        <SupportForm/>
        </div>
        
        <div>
        

          <div style={{ marginTop: "5px", marginBottom: "45px" }}>

            <center><p>Sungowin Technologies Pvt. Ltd.</p></center>
            <center><p>Email Id: info@drplanete.com</p></center>
            <center><p>Phone No: +91 8619460759 </p></center>

            </div>
          <div>
          </div>

          
        </div>
        <div>
        <FooterNext />
        </div>
      </div>
    )
  }
}

export default Contact;