import React from "react";

import doctorImage from "../images/hospitaldocsmall.png";


/*const HospitalSectionOne = () => {
  return (
    <div className="container">
      <div className="image-wrapper">
        <img
          src={doctorImage} // Replace with the actual path
          alt="Doctor Meme"
          className="image"
        />
        <div className="text top-left">Anesthetist ke liye chai lao</div>
        <div className="text center-left bold">Patients Manage Karo</div>
        <div className="text top-right">Gynecologist ke nakhre jhelo</div>
        <div className="text bottom-right">Staff ko dekho</div>
      </div>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

          .container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            background-color: black;
            padding: 16px;
          }

          .image-wrapper {
            position: relative;
            width: 100%;
            max-width: 100%;
          }

          .image {
            width: 40%;
            border-radius: 8px;
          }

          .text {
            position: absolute;
            color: white;
            font-size: 35px;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
          }

          .bold {
            font-size: 20px;
            font-weight: bold;
          }

          .top-left {
            top: 25%;
            left: 35%;
          }

          .center-left {
            top: 5%;
            left: 35%;
          }

          .top-right {
            top: 35%;
            right: 5%;
          }

          .bottom-right {
            bottom: 15%;
            right: 45%;
          }

          @media (max-width: 768px) {
            .text {
              font-size: 12px;
            }
            .bold {
              font-size: 16px;
            }
          }
        `}
      </style>
    </div>
  );
};*/

/*const HospitalSectionOne = () => {
  return (
    <div className="container">
      <div className="grid-wrapper">
        
        <div className="text top-left">Anesthetist ke liye chai lao</div>
        <div className="text top-right">Gynecologist ke nakhre jhelo</div>

        
        <div className="image-container">
          <img
            src={doctorImage} // Replace with actual image path
            alt="Doctor Meme"
            className="image"
          />
        </div>

        
        <div className="text bottom-left bold">Patients Manage Karo</div>
        <div className="text bottom-right">Staff ko dekho</div>
      </div>

      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

          .container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 80vh;
            background-color: black;
            padding: 40px 5%;
          }

          .grid-wrapper {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-template-rows: auto auto;
            align-items: center;
            gap: 40px;
            width: 80%;
            max-width: 1200px;
            text-align: center;
          }

          .image-container {
            grid-column: 2;
            grid-row: span 2;
            display: flex;
            justify-content: center;
          }

          .image {
            width: 100%;
            max-width: 750px; 
            border-radius: 12px;
          }

          .text {
            color: white;
            font-size: 2rem; 
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            text-align: center;
            max-width: 400px;
          }

          .bold {
            font-size: 2.2rem;
            font-weight: bold;
          }

          .top-left {
            grid-column: 1;
            grid-row: 1;
          }

          .top-right {
            grid-column: 3;
            grid-row: 1;
          }

          .bottom-left {
            grid-column: 1;
            grid-row: 2;
          }

          .bottom-right {
            grid-column: 3;
            grid-row: 2;
          }

         
          @media (max-width: 768px) {
            .grid-wrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              gap: 20px;
            }

            .image-container {
              order: 1;
            }

            .image {
              max-width: 300px; 
            }

            .text {
              font-size: 1.5rem;
              max-width: 90%;
            }

            .bold {
              font-size: 1.7rem;
            }
          }
        `}
      </style>
    </div>
  );
};*/

/*const HospitalSectionOne = () => {
  return (
    <div className="container" style={{background:"chocolate"}}>
      <div className="content">
        
        <div className="text left">Anesthetist ke liye chai lao</div>
        <div className="text center-left bold">Patients Manage Karo</div>

       
        <div className="image-container" style={{background:"red"}}>
          <img
            src={doctorImage} 
            alt="Doctor Meme"
            className="image"
            style={{background:"blue"}}
          />
        </div>

        
        <div className="text center-right">Gynecologist ke nakhre jhelo</div>
        <div className="text right">Staff ko dekho</div>
      </div>

      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 80vh;
            background-color: black;
            padding: 40px 5%;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            max-width: 900px;
            text-align: center;
            position: relative;
          }

          .image-container {
            flex-shrink: 0;
            position: relative;
            left:15%;
            width:80%
          }

          .image {
            width: 80%;
            max-width: 650px;
            border-radius: 12px;
            background:"red";
          }

          .text {
            position: absolute;
            color: white;
            font-size: 2rem; 
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            text-align: left;
          }

          .bold {
            font-size: 2.2rem;
            font-weight: bold;
          }

          
          .left {
            left: -15%;
            top: 25%;
          }

          .center-left {
            left: -10%;
            top: 50%;
          }

          .center-right {
            right: -20%;
            top: 25%;
          }

          .right {
            right: -20%;
            top: 50%;
          }

         
          @media (max-width: 768px) {

          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 80vh;
            background-color: black;
            padding: 0px 1%;
          }
            .content {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .text {
              position: static;
              font-size: 1.5rem;
              margin-bottom: 10px;
              font-weight: 700;
              text-align: center;
            }

          
            .bold {
              font-size: 1.7rem;
            }

           

            .image-container {
            flex-shrink: 0;
            position: relative;
            left:1%;
            width:100%
          }

          .image {
            width: 90%;
            border-radius: 12px;
            background:"red";
          }

          }
        `}
      </style>
    </div>
  );
};*/

/*const HospitalSectionOne = () => {
  return (
    <div className="container" style={{ background: "chocolate" }}>
      <div className="content">
       
        <div className="text left">Anesthetist ke liye chai lao</div>
        <div className="text center-left bold">Patients Manage Karo</div>

       
        <div className="image-container" style={{ background: "red" }}>
          <img
            src={doctorImage} // Replace with actual image path
            alt="Doctor Meme"
            className="image"
            style={{ background: "blue" }}
          />
        </div>

        
        <div className="text center-right">Gynecologist ke nakhre jhelo</div>
        <div className="text right">Staff ko dekho</div>
      </div>

      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 80vh;
            background-color: black;
            padding: 40px 5%;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            max-width: 900px;
            text-align: center;
            position: relative;
          }

          .image-container {
            flex-shrink: 0;
            position: relative;
            left: 15%;
            width: 80%;
          }

          .image {
            width: 80%;
            max-width: 650px; 
            border-radius: 12px;
          }

          .text {
            position: absolute;
            color: white;
            font-size: 2rem; 
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            text-align: left;
            white-space: nowrap; 
          }

          .bold {
            font-size: 2.2rem;
            font-weight: bold;
          }

          
          .left {
            left: -15%;
            top: 25%;
          }

          .center-left {
            left: -10%;
            top: 50%;
          }

          .center-right {
            right: -20%;
            top: 25%;
          }

          .right {
            right: -20%;
            top: 50%;
          }

          
          @media (max-width: 768px) {
            .container {
              padding: 20px 5%;
            }

            .content {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .text {
              position: static;
              font-size: 1.6rem;
              margin-bottom: 10px;
              font-weight: 700;
              text-align: center;
              white-space: nowrap; 
            }

            .bold {
              font-size: 1.8rem;
            }

            .image-container {
              position: relative;
              width: 100%;
              left: 0;
            }

            .image {
              width: 90%;
              border-radius: 12px;
            }
          }
        `}
      </style>
    </div>
  );
};*/


/*
const HospitalSectionOne = () => {
  return (
    <div className="container" style={{ background: "chocolate" }}>
      <div className="content">

        
        <div className="text left">Anesthetist ke liye chai lao</div>
        <div className="text center-left bold">Patients Manage Karo</div>

        
        <div className="image-container" style={{ background: "red" }}>
          <img
            src={doctorImage} // Replace with actual image path
            alt="Doctor Meme"
            className="image"
            style={{ background: "blue" }}
          />
        </div>

       
        <div className="text center-right">Gynecologist ke nakhre jhelo</div>
        <div className="text right">Staff ko dekho</div>
      </div>

      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 80vh;
            background-color: black;
            padding: 40px 5%;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            max-width: 900px;
            text-align: center;
            position: relative;
          }

          .image-container {
            flex-shrink: 0;
            position: relative;
            left: 15%;
            width: 80%;
          }

          .image {
            width: 80%;
            max-width: 650px;
            border-radius: 12px;
          }

          .text {
            position: absolute;
            color: white;
            font-size: 2rem;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            text-align: left;
          }

          .bold {
            font-size: 2.2rem;
            font-weight: bold;
          }

          
          .left {
            left: -15%;
            top: 25%;
          }

          .center-left {
            left: -10%;
            top: 50%;
          }

          .center-right {
            right: -20%;
            top: 25%;
          }

          .right {
            right: -20%;
            top: 50%;
          }

          
          @media (max-width: 768px) {
            .container {
              padding: 20px 5%;
            }

            .content {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .text {
  position: static;
  font-size: min(2.2rem, 5vw); 
  max-width: 80vw; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  font-weight: 700;
  text-align: center;
  margin: 12px auto; 
  padding: 0 1%; 
}

.text:only-child, 
.text.short-text {
  font-size: min(3rem, 6vw); 



            .bold {
              font-size: clamp(1.2rem, 4.5vw, 1.8rem);
            }

            .image-container {
              position: relative;
              width: 100%;
              left: 0;
            }

            .image {
              width: 90%;
              border-radius: 12px;
            }
          }
        `}
      </style>
    </div>
  );
};*/



const HospitalSectionOne = () => {
  const highlightText = (text) => {
    const words = text.split(" ");
    if (words.length <= 3) {
      return (
        <>
          {words.slice(0, -1).join(" ")}{" "}
          <span style={{ color: "#ff007f" }}>{words[words.length - 1]}</span>
        </>
      );
    } else {
      return (
        <>
          {words.slice(0, -2).join(" ")}{" "}
          <span style={{ color: "#ff007f" }}>{words.slice(-2).join(" ")}</span>
        </>
      );
    }
  };

  return (
    <div className="sectiononecontainer" style={{ background: "black" }}>
      <div className="content">
        {/* Left Text */}
        <div className="text left">{highlightText("Anesthetist ke liye chai lao")}</div>
        <div className="text center-left bold">{highlightText("Patients Manage Karo")}</div>

        {/* Center Image */}
        <div className="image-container" style={{background:"transparent"}} >
          <img
            src={doctorImage} // Replace with actual image path
            alt="Doctor Meme"
            className="image"
            style={{background:"transparent"}}
          />
        </div>

        {/* Right Text */}
        <div className="text center-right">{highlightText("Gynecologist ke nakhre jhelo")}</div>
        <div className="text right">{highlightText("Staff ko dekho")}</div>
      </div>

      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

          .sectiononecontainer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 80vh;
            background-color: black;
            padding: 40px 5%;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            max-width: 900px;
            text-align: center;
            position: relative;
          }

          .image-container {
            flex-shrink: 0;
            position: relative;
            left: 15%;
            width: 80%;
          }

          .image {
            width: 80%;
            max-width: 650px;
            border-radius: 12px;
          }

          .text {
            position: absolute;
            color: white;
            font-size: 2rem;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            text-align: left;
          }

          .bold {
            font-size: 2.2rem;
            font-weight: bold;
          }

          /* Positioning Text Around Image */
          .left {
            left: -25%;
            top: 25%;
          }

          .center-left {
            left: -15%;
            top: 50%;
          }

          .center-right {
            right: -20%;
            top: 25%;
          }

          .right {
            right: -20%;
            top: 50%;
          }

          /* Responsive for Mobile */
          @media (max-width: 768px) {
            .sectiononecontainer {
              padding: 20px 5%;
            }

            .content {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .text {
              position: static;
              font-size: min(2.2rem, 5vw); 
              max-width: 80vw; 
              white-space: nowrap; 
              overflow: hidden; 
              text-overflow: ellipsis; 
              font-weight: 700;
              text-align: center;
              margin: 12px auto; 
              padding: 0 1%;
            }

            .bold {
              font-size: clamp(1.2rem, 4.5vw, 1.8rem);
            }

            .image-container {
              position: relative;
              width:120%;
              margin-right:25%;
              display:flex;
              justify-content:center;
              
            }

            .image {
              width: 100%;
              border-radius: 1px;
              
            }
          }
        `}
      </style>
    </div>
  );
};





export default HospitalSectionOne;
