import React from "react";
import ReactDOM from 'react-dom';
import { firebase, cheq, db, database } from "../../firebaseConfig";
import { useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    Link,
} from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button } from "react-bootstrap";

import { DayPicker } from 'react-day-picker';
import FooterNew from "../../Footers/Footer";
import GetApp from "../../Footers/getApp";
import BasicDetails from "./HospitalbasicDetails";
import CourseDetails from "./courseDetails";
import EligibleSpecialists from "./eligibleSpecialist";
import Sectors from "./sectors";
import { render } from "@testing-library/react";
import Login from "../../Login";
import MedicalStudent from "../../medicalstudent/MedicalStudent";
import { Column } from "../../Footers/FooterStyles";
import FooterNext from "../../Footers/FooterNext";
import DashboardDoctor from "../../doctor/dashboardDoctor";
import EducatorDetails from "../../doctor/educators/educatorDetails";
import Hospital from "../Hospital";
import HospitalBasicDetails from "./HospitalbasicDetails";
import Doctor from "../../doctor/Doctor";


function Example(props)
{
    if(!props.toDisplay)
        return null;
    else
        return <h1>Component is rendered</h1>;
}

function checkifHospitalIsRegistered(childName) {
    return new Promise((resolve, reject) => {
      const ref = database.ref(childName);
      ref.once("value", (snapshot) => {
        
        const exists = snapshot.exists();
        resolve(exists);
      }, (error) => {
        reject(error);
      });
    });
}

export default function TrainingCentre()
{
    //var user = firebase.auth().currentUser;
    const [user] = useAuthState(cheq);
    let [regis, setRegis] = useState("false");

    var phone = "hello";
    if (user != null) {
        phone = cheq.currentUser.phoneNumber;
    }

    checkifHospitalIsRegistered(`Hospital/${phone}`)
    .then((exists) => {
      if (exists) {
        setRegis("true");
        console.log("Child exists in the database.");
      } else {
        console.log("Child does not exist in the database.");
      }
    })
    .catch((error) => {
      console.error(error);
    });
  
 
    return(
    
     <div>

        <HospitalBasicDetails/>
            {/*{
                (regis=="true") &&
                <Doctor/>
            }{
                (regis=="false") &&
                <div><EducatorDetails/></div>
            }*/}
           </div>
        );
}


 
ReactDOM.render(
    <TrainingCentre />,
    
    document.getElementById('root')
);

{/*export default function TrainingCentre({ }) {

    

    return (

        user ?

            { regis } ?
                <div>

                    <DashboardDoctor/>
                </div> :


                <div>
                    <div>{phone}</div>
                    <div><BasicDetails /></div>
                </div>


            : <Login />

    );
    //alert("value - " + isRegistered);


}*/

{/*isRegistered ? <div><div>{cheq.currentUser.phoneNumber}</div>Is Registered </div> : <div><div>{cheq.currentUser.phoneNumber}</div><BasicDetails /></div>*/ }

{/* 
{regis} ? <div><div>{cheq.currentUser.phoneNumber}</div>Is Registered </div> : <div><div>{cheq.currentUser.phoneNumber}</div><BasicDetails /></div>
     
*/}
{/*

{regis} ? <div>If Yes</div> : <div>Go for it</div>
*/}
{/*<div>
                Show this 
                <div><h3>{regis}</h3></div>
                </div>*/ }

{/* 
user ? 
            <div>
                Show this 
                <div><h3>{regis}</h3></div>
                </div> : <Login/>
*/}
{/*
<div>

            <div><h1>Training Centre Enrollment</h1></div>

            
            {/*<div><BasicDetails /></div>
            <div style={{ marginTop: "15px", marginBottom: "15px" }}><Sectors /></div>
            <div><CourseDetails/></div>
            <div class="container" style={{marginTop:"15px", marginLeft:"75%"}}>
                <Button variant="primary" size="lg" style={{ width: "150px", marginBottom: "25px" }}>Save & Next</Button>
    </div>*/}
{/*<div style={{ marginTop: "45px" }}><GetApp /></div>
    <div><FooterNew /></div>
</div>*/}}