import React from 'react';

const InformativeArticlesDisclaimer = () => {
  return (
    <div className="disclaimer-container">
      <p className="disclaimer-title">Disclaimer:</p>
      <p className="disclaimer-text">
        The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.
      </p>
    </div>
  );
};

export default InformativeArticlesDisclaimer;

// CSS classes inside the same file
const styles = `
  .disclaimer-container {
    margin: 5%;
  }

  .disclaimer-title {
    font-style: italic;
    font-size: 15px;
    color: lightgray;
  }

  .disclaimer-text {
    font-style: italic;
    font-size: 11px;
    color: lightgray;
  }

  @media (max-width: 768px) {
    .disclaimer-container {
      margin: 3%;
    }
    .disclaimer-title {
      font-size: 10px;
    }
    .disclaimer-text {
      font-size: 8px;
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
