import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import firebase from "firebase";
import { db } from "../firebaseConfig";
import "../index.css";
import "./MedicalStudent.css";
import Post from "./Post.js";
import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";
import ShowColumn from "../components/showColumn";
import HorizontalScrollSlider from "../components/horizontalScroll";
import GetApp from "../Footers/getApp";
import FooterNew from "../Footers/Footer";
import Todo from "../components/base/Todo";
import Next from "../components/showBranchImages";
import Slideshow from "./slideshow_medicalstudent";
import ShowProducts from "../components/showProducts";
import Offers from "./Offers";
import ShowBranches from "../components/showBranches";
import ShowExperts from "../components/showExperts";
import ShowNews from "../components/newssection/shownews";
import PlayVideo from "../components/videoplayer";
import StateCity from "../components/statecity";
import ShowMemes from "../components/showMemes";
import FooterNext from "../Footers/FooterNext";
import { Link } from "react-router-dom";
import MedStudentSectionOne from "./newContentstudent/medstudentSectionOne.js";
import MedStudentSectionTwo from "./newContentstudent/medstudentSectionTwo.js";
import ToxicSeniorsSection from "./newContentstudent/medstudentSectionThree.js";
import FunGamesSection from "./newContentstudent/medstudentSectionFour.js";
import BecomeAMember from "../club/membershipInstructions.js";




function MedicalStudent() {
  const [startDate, setStartDate] = useState(new Date().toDateString());


  const [todoList, setTodoList] = useState();
  const [nextList, setNextList] = useState();

  useEffect(() => {
    const todoRef = firebase
      .database()
      .ref("todo");
    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setTodoList(todoList);
    });

    const showImageRef = firebase.database().ref("next");
    showImageRef.on("value", (snapshot) => {
      const n = snapshot.val();
      const nextList = [];
      for (let id in n) {
        nextList.push({ id, ...n[id] });
      }
      setNextList(nextList);
    });

  }, []);

  const [post, setPosts] = useState([]);
  const [postsX, setPostsX] = useState([]);
  db.collection("slideshowschool").onSnapshot((snapshot) => {
    setPostsX(snapshot.docs.map((doc) => ({ id: doc.id, postsX: doc.data() })));
  });

  return (
    <div>

      <div class="container" style={{ marginTop: "auto", height: "400px" }}><Slideshow path="slideshow/medicalstudent/get" /></div>

      <div className="showImg" style={{ display: "fixed" }}><HorizontalScrollSlider path="showtype" /></div>

  
      <div style={{ display: "flex", marginTop: "15px" }}>
        <div class="container body-content">
          <div className="columns-2">
            {postsX.map(({ id, postsX }) => {
              return (
                <ShowColumn
                  key={id}
                  slideshow_image={postsX.slideshow_image}
                  onClickfunction={postsX.onClickfunction}
                />
              );
            })}
          </div>
        </div>
          </div>

      <div className="show-social" style={{ marginTop: "35px" }}>
        <div class="container">
        <center>
          <Link to="social">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/website-c857f.appspot.com/o/magzine%2Fcover%2Fins-iw.png?alt=media&token=d77ac863-9c58-48ae-a00c-8bef914d3cdd"
            />
          </Link>
        </center>
        </div>
        </div>

      <div class="container" style={{ marginTop: "15px", marginBottom: "15px" }}>
        <div className="set-together">
          <div className="showNews">
            <ShowNews />
          </div>
          <div className="showVideo">
            <PlayVideo />
          </div>
        </div>
      </div>

      {/*<div>//working realtime database fetch
      {todoList
        ? todoList.map((todo, index) => <Todo todo={todo} key={index} />)
        : ''}
      </div>*/}

      {/*<div>//next working realtime database fetch
      {nextList
        ? nextList.map((next, index) => <Next next={next} key={index} />)
        : ''}
      </div>*/}

      <div style={{ marginTop: "35px", marginBottom: "35px" }}>
        <ShowMemes />
    </div>

      <div class="container">
        <ShowBranches specialization="nice" />
  </div>


      <FooterNext />
      {/*<GetApp page="medicalstudent" date={startDate} />
      <FooterNew />*/}
    </div>
  );
}

function MedicalStudentView(){

  return(
    <div style={{ marginTop: "5%" }}>
      

      <div style={{marginLeft:"15%", marginRight:"15%"}}>
      <h1 style={{fontSize:"75px"}}>Hello Future Doctor</h1>
      <p style={{marginTop:"-2%", marginLeft:"0.5%", color:"lightslategray"}}>Glad to have you here</p>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%"}}>
        <h2 style={{color:"blue"}}>The very first thing to do once you get registered with Medical Council of India/ State Medical Council is</h2>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      <div style={{marginLeft:"15%", marginRight:"15%", marginTop:"-1%"}}>
        <h1 style={{fontWeight:"lighter"}}>.</h1>
      </div>

      

      <div style={{marginLeft:"15%", marginRight:"15%", marginBottom:"5%"}}>
        <h1 style={{fontSize:"50px"}}>Become a Doctor member of Dr Planete</h1>
      </div>
       
      <FooterNext/> 
    </div>
  )
}

function MedicalStudentNew(){
  return (

    <div style={{background:"black"}}>
       <style>
        {`
          /* Main Container */
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            padding: 50px 20px;
            min-height: 50vh; /* Reduced height */
          }

          .pinkhighlig{
          color:#ff007f;
            font-weight: 600;
            
          }
        `}
      </style>
        <div style={{marginLeft:"5%", marginRight:"15%", background:"black"}}>
        <h1 style={{fontSize:"25px", color:"black", fontFamily: 'Poppins', fontWeight:600}}>Hello Future Doctor</h1>
              <h1 style={{fontSize:"75px", color:"white", fontFamily: 'Poppins', fontWeight:600}}>Hello <span className="pinkhighlig">Future Doctor</span></h1>
      <p style={{marginTop:"-2%", marginLeft:"0.5%", color:"lightslategray"}}>Glad to have you here</p>
      </div>

      <MedStudentSectionOne/>

      <MedStudentSectionTwo/>

      <ToxicSeniorsSection/>

      <FunGamesSection/>

      <BecomeAMember/>

      <FooterNext/>
    </div>
  )
}
export default MedicalStudentNew;
