/*import React from "react";

const MedStudentSectionTwo = () => {
  return (
    <div className="container">
      <style>
        {`
         
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            padding: 50px 20px;
            min-height: 50vh; 
          }

          
          .subtext {
            font-size: 1.5rem;
            font-weight: 600;
            opacity: 0;
            animation: fadeIn 2s ease-in-out forwards;
          }

          
          .main-text {
            font-size: 4.5rem;
            font-weight: 700;
            margin-top: 10px;
            opacity: 0;
            transform: scale(0);
            animation: popIn 0.6s ease-in-out forwards 1s;
          }

          

          .pinkhighligt{
          color:#ff3b77;
            font-weight: 800;
            
          }
          .highlighted {
            color:rgb(3, 255, 41);
            font-weight: 800;
          }

          
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes popIn {
            from { opacity: 0; transform: scale(0); }
            to { opacity: 1; transform: scale(1); }
          }

          
          @media (max-width: 768px) {
            .container {
              min-height: 30vh;
              padding: 20px;
            }
            .main-text {
              font-size: 2.5rem;
            }
            .subtext {
              font-size: 1.2rem;
            }
          }
        `}
      </style>

      
      <div className="subtext"></div>

      <div>

      <h1 className="main-text">
      Hum 
        <span className="highlighted" style={{marginLeft:"1%", marginRight:"1%"}}>
        padhai 
        </span>
         ki baat nahi karte
         </h1>
      </div>
      
      <div>
        <h1 className="main-text">
      <br />
        <span className="pinkhighligt" style={{marginLeft:"1%", marginRight:"1%"}}>syllabus </span>
        toh kabhi puchna hi mat
      </h1>
      </div>
    </div>
  );
};

export default MedStudentSectionTwo;

*/

import React from "react";

const MedStudentSectionOne = () => {
  return (
    <div className="container">
      <style>
        {`
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            padding: 50px 20px;
            min-height: 50vh;
            overflow: hidden;
          }

          .text-line {
            font-size: 4rem;
            font-weight: 700;
            opacity: 0;
            white-space: nowrap;
            overflow: hidden;
            animation: fadeIn 1s ease-in-out forwards;
          }

          .highlight {
            color: rgb(3, 255, 41);
            font-weight: 800;
          }

          .pink-highlight {
            color: #ff3b77;
            font-weight: 800;
          }

          .second-line {
            animation-delay: 2s;
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @media (max-width: 768px) {
            .container {
              min-height: 30vh; /* Reduced height on mobile */
              padding: 20px;
               align-items: left;
            justify-content: left;
            }
            .text-line {
            text-align:left;
            }
          }
        `}
      </style>

      <div style={{fontSize:"60px", color:"white", fontFamily: 'Poppins', fontWeight:600, textAlign:"left", marginTop:"1%", marginBottom:"1%" }}>Hum <span className="highlight">padhai</span> ki baat nahi karte</div>
      <div style={{fontSize:"60px", color:"white", fontFamily: 'Poppins', fontWeight:600, textAlign:"left", marginTop:"1%", marginBottom:"1%"}}> <span className="pink-highlight">Syllabus</span> toh kabhi puchna hi mat</div>
    </div>
  );
};

export default MedStudentSectionOne;
