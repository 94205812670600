import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';
import InformativeArticlesDisclaimer from '../../informativeArticlesDisclaimer';

const questions = [
    "Why do I need to register with the Haryana Medical Council?",
    "What happens if I practice medicine in Haryana without HMC registration?",
    "Who is required to register with the HMC?",
    "Where can I find the application form and information?",
    "Is the registration process online?",
    "What documents are required for registration?",
    "How much are the registration fees?",
    "How do I submit my documents?",
    "How long does the registration process take?",
    "How will I receive my registration certificate?",
    "What is the purpose of the Matriculation/High School Certificate?",
    "Are there specific requirements for the passport-size photographs?",
    "How can I contact the Haryana Medical Council?"
];

const answers = [
    "It is a legal requirement to practice medicine in Haryana, validates your qualifications, and is necessary for employment.",
    "You will be practicing illegally, which can result in legal penalties and damage your professional reputation.",
    "All doctors who intend to practice medicine in Haryana, regardless of where they obtained their medical degree, must register.",
    "All information and the online registration portal is located on the official Haryana Medical Council website: https://haryanamedicalcouncil.org/.",
    "Yes, the Haryana Medical Council has online registration available.",
    "Required documents include: Passport-size photographs, Matriculation/High School Certificate (10th), 10+2/Intermediate/Senior Secondary School Examination Certificate & Detailed Marks Sheet, MBBS or equivalent degree certificate, Internship completion certificate, Aadhar Card. Always check the official website for the most current list.",
    "Permanent Registration (After Passing MBBS): Rs 3100. Permanent Registration (Graduates From Out Of Haryana): Rs 5100. Permanent Registration (Foreign Graduates): Rs 20100.",
    "Follow the instructions on the official Haryana Medical Council website regarding the upload of documents.",
    "Processing times can vary. It's best to allow sufficient time and check the HMC website for updates.",
    "Upon successful verification, you will receive your permanent registration certificate.",
    "It serves as proof of your date of birth.",
    "Ensure the photographs are recent and meet the requirements specified on the HMC website.",
    "Refer to the official Haryana Medical Council website for contact information." 
];

const HaryanaMedicalRegistration = () => {
    return (
      <div style={{background:"black"}}>
        <div style={{ height: "5%" }}></div>
      <Container>
        <Helmet>
          <title>Haryana Medical Council Registration: Step-by-Step Guide</title>
          <meta
            name="description"
            content="Learn how to apply for permanent medical registration in Haryana. Get detailed steps, required documents, and official links."
          />
          <meta
            name="keywords"
            content="Haryana medical council registration, medical registration in Haryana, how to register as a doctor in Haryana, doctors registration Haryana, Haryana medical license"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-haryana-medical-registration" />
        </Helmet>
  
        <Main>
          <Section>
            <H1>Haryana Medical Council Registration: A Simple Breakdown</H1>
  
            <H2 style={{color: "#ff007f"}}>Why is Haryana Medical Council Registration Important?</H2>
            <P>
              <strong>Legal Compliance:</strong> It's a legal requirement to practice medicine in Haryana.
              <br/><strong>Professional Recognition:</strong> It establishes your credibility as a qualified medical professional.
              <br/><strong>Employment Opportunities:</strong> Healthcare facilities in Haryana require proof of HMC registration.
            </P>
  
            <H2 style={{color: "#ff007f"}}>What Happens if You Don't Register?</H2>
            <P>
              <strong>Illegal Practice:</strong> You'll be practicing medicine illegally, which can result in legal consequences.
              <br/><strong>Professional Credibility Issues:</strong> Your professional reputation may be compromised.
            </P>
  
            <H2 style={{color: "#ff007f"}}>Who Needs to Register?</H2>
            <P>
              <UL>
                <LI>All doctors who intend to practice medicine within the state of Haryana.</LI>
                <LI>This includes graduates from medical colleges in Haryana and those from other states or countries.</LI>
                <LI>The responsibility for registration lies with the individual doctor.</LI>
              </UL>
            </P>
  
            <H2 style={{color: "#ff007f"}}>How to Apply for Permanent Registration with the HMC:</H2>
            <H3 style={{color: "#FFFF00"}}>Step-by-Step Process:</H3>
            <UL>
              <LI><strong>Visit the Haryana Medical Council Website:</strong> <a href="https://haryanamedicalcouncil.org/" target="_blank" rel="noopener noreferrer">www.haryanamedicalcouncil.org</a></LI>
              <LI><strong>Gather Required Documents:</strong>
                <UL>
                  <LI>Passport-size photographs.</LI>
                  <LI>Matriculation/High School Certificate (10th) for proof of date of birth.</LI>
                  <LI>10+2/Intermediate/Senior Secondary School Examination Certificate & Detailed Marks Sheet.</LI>
                  <LI>MBBS or equivalent degree certificate.</LI>
                  <LI>Internship completion certificate.</LI>
                  <LI>Aadhar Card.</LI>
                  <LI>Other documents as specified on the HMC website.</LI>
                </UL>
              </LI>
              <LI><strong>Online Registration:</strong> Follow the instructions provided on the official website for online registration.</LI>
              <LI><strong>Document Submission and Verification:</strong> Submit your documents for verification. The HMC will verify your qualifications.</LI>
              <LI><strong>Fee Payment:</strong>
                <UL>
                  <LI>Permanent Registration (After Passing MBBS): Rs 3100</LI>
                  <LI>Permanent Registration (Graduates From Out Of Haryana): Rs 5100</LI>
                  <LI>Permanent Registration (Foreign Graduates): Rs 20100</LI>
                  <LI>Renewal of Registration: Rs 3100</LI>
                  <LI>Good Standing Certificate: Rs 5100</LI>
                  <LI>Additional Qualification Registration: Rs 2100</LI>
                  <LI>NOC for Other States: Rs 5100</LI>
                  <LI>Duplicate Registration Certificate: Rs 3100</LI>
                </UL>
              </LI>
              <LI><strong>Receive Your Registration Certificate:</strong> Upon successful verification, you'll receive your permanent registration certificate.</LI>
            </UL>
  
            <H2 style={{color: "#ff007f"}}>Important Notes:</H2>
            <P>
              <UL>
                <LI>Always refer to the official Haryana Medical Council website for the most accurate and up-to-date information.</LI>
                <LI>Ensure that you have all the required documents before starting the application process.</LI>
                <LI>Be prepared for potential processing times.</LI>
                <LI>Contact the Haryana Medical Council offices directly for the most updated details.</LI>
              </UL>
            </P>
          </Section>
        </Main>
        </Container>
  
        <FAQFunction questions={questions} answers={answers}/> 

        <InformativeArticlesDisclaimer/>
        <FooterNext/>
      </div>
    );
  };
  
  // Styled Components
  const Container = styled.div`
    background-color: #000;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    text-align: left;
    min-height: 100vh;
  `;
  
  const Main = styled.main`
    max-width: 85%;
    margin: 0 auto;
  `;
  
  const Section = styled.section`
    margin-bottom: 30px;
    margin-top: 45px;
  `;
  
  const H1 = styled.h1`
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 100;
    margin-top: 45px;
  `;
  
  const H2 = styled.h2`
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 200;
  `;
  
  const H3 = styled.h3`
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 35px;
  `;
  
  const P = styled.p`
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 35px;
  `;
  
  const UL = styled.ul`
    list-style-type: disc;
    padding-left: 25px;
    margin-bottom: 15px;
  `;
  
  const LI = styled.li`
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 8px;
  `;
  
  export default HaryanaMedicalRegistration;
  