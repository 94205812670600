import React from "react";



const HospitalSectionFour = () => {
  return (
    <div className="sectionfourcontainer">
      <style>
        {`
          /* Main Container */
          .sectionfourcontainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            padding: 50px 20px;
            min-height: 50vh; /* Reduced height */
          }

          /* Subtext with Fade-in Animation */
          .subtext {
            font-size: 1.5rem;
            font-weight: 600;
            opacity: 0;
            animation: fadeIn 2s ease-in-out forwards;
          }

          /* Main Heading with Pop-in Effect */
          .main-text {
            font-size: 4rem;
            font-weight: 700;
            margin-top: 10px;
            opacity: 0;
            transform: scale(0);
            animation: popIn 0.6s ease-in-out forwards 1s;
          }

          /* Highlighted Text */
          .highlight {
            color: #ff3b77;
            font-weight: 800;
          }

          /* Animations */
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes popIn {
            from { opacity: 0; transform: scale(0); }
            to { opacity: 1; transform: scale(1); }
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .sectionfourcontainer {
              min-height: 30vh; /* Reduced height on mobile */
              padding: 20px;
            }
            .main-text {
              font-size: 2.5rem;
            }
            .subtext {
              font-size: 1.2rem;
            }
          }
        `}
      </style>

      {/* Subtext */}
      <div className="subtext"></div>

      {/* Main Heading */}
      <h1 className="main-text">
        Tension Mat Lo <br />
        <span className="highlight">Dr Planete Hospital Membership Lo</span>
      </h1>
    </div>
  );
};

export default HospitalSectionFour;

