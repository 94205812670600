import React from "react";
import { cheq, storage, db } from "../firebaseConfig";
import firebase from "firebase";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import { MDCTextField } from "@material/textfield";
import { TextField } from "@material-ui/core";
import Home from "../Home";
import './hospital.css';

import FooterNext from "../Footers/FooterNext";
import DoctorMeme from "./newContent/hospitalSectionOne";
import HospitalSectionOne from "./newContent/hospitalSectionOne";
import BecomeAMember from "../club/membershipInstructions";
import HospitalSectionTwo from "./newContent/hospitalSectionTwo";
import HospitalSectionThree from "./newContent/hospitalSectionThree";
import HospitalSectionFour from "./newContent/hospitalSectionFour";
import DeliveryPatientAd from "./newContent/hospitalSectionFive";
import FAQFunction from "../blog/informative articles/faq_segment/faqFunction";


/*function HospitalView() {

  return (
    <div style={{ background:"black" }}>

<div style={{marginTop:"-10%"}}>
<HospitalSectionTwo/>
</div>


<div style={{marginTop:"-15%"}}>
<HospitalSectionOne/>
</div>
       

       
      

       <HospitalSectionThree/>

       <BecomeAMember/>
     

     

      <FooterNext/>
    </div>
  )
}*/


const questions = [
  "Who can become a member of Dr Planete?",
  "What are the different types of hospital memberships at Dr Planete?",
  "Difference between Dr Planete's Hospital Membership and Doctor Membership?",
];

const answers = [
  "Only doctors, hospitals and medical students registered in India can become a member of Dr Planete",
  "Only lifetime Dr Planete's Hospital Membership is available",
  "Hospital Membership is different from Dr Planete's Doctor Membership.Dr Planete's hospital membership is exclusively for hospitals.",
];

function HospitalView() {
  return (
    <div className="hospital-container">
      <style>{`
        .hospital-container {
          background: black;
        }

        .hospital-section-two {
          margin-top: -5%;
        }

        .hospital-section-one {
          margin-top: -15%;
        }

        @media (max-width: 768px) { /* Mobile */
          .hospital-section-two {
            margin-top: 0;
          }

          .hospital-section-one {
            margin-top: -15%;
          }
        }
      `}</style>
      <div className="hospital-section-two">
        <HospitalSectionTwo />
      </div>
      <div className="hospital-section-one">
        <HospitalSectionOne />
      </div>

      <div>
        <HospitalSectionFour/>
      </div>

      <div>
        <DeliveryPatientAd/>
      </div>
      <HospitalSectionThree />
      <BecomeAMember />
      
      <FAQFunction questions={questions} answers={answers}/>
      <FooterNext />
    </div>
  );
}

export default HospitalView;

 {/*<div style={{ marginLeft: "15%", marginRight: "15%", marginBottom:"15%" }}>
        <div>
          <h1 style={{ fontSize: "70px" }}>Tough Job?? Running a hospital</h1>
        </div>

        <div style={{marginLeft:"1.5%"}}>
        <p>Make it easier than ever with Dr Planete - Become a member today</p>
        </div>
        
        <div style={{ marginTop: "5%" }}>
          <h4 style={{ color: "blue", fontWeight: "bold" }}>Welcome to Dr Planete</h4>
          <h5 style={{ color: "orangered", fontWeight: "bold", marginTop: "-1%" }}>The Premier Club - Only for Doctors, Hospitals & Medical Students in India</h5>
        </div>

        <div style={{ marginTop: "5%" }}>
          <h4 style={{ color: "purple", fontWeight: "bold" }}>5 Reasons to become Dr Planete Hospital Member</h4>
        </div>

        <div>
          <p><b>Services of a Personal Marketing Advisor</b> <p style={{ color: "lightslategray", fontWeight: "light", marginTop: "0%" }}>for your practice offering In-Person Guidance to enhance your personal brand</p></p>
        </div>

        <div>
          <p><b>Access to Events and Conferences </b> <p style={{ color: "lightslategray", fontWeight: "light", marginTop: "0%" }}>Organized and hosted by Dr Planete</p>
          </p>
        </div>

        <div>
          <p><b>Find On-Call Specialist and Super Specialist Doctors </b></p>
        </div>

        <div>
          <p><b>Post Vacancies for doctors, visible PAN India</b></p>
        </div>

        <div>
          <p>View Requests for health camps/awarness sessions from Schools and Industrial Organizations</p>
        </div>
      </div>*/}