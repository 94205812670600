import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components'; // Install with: npm install styled-components
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';

const questions = [
  "How long does the registration process take?",
  "What documents are required for registration?",
  "Can I apply online?",
];

const answers = [
  "The processing time varies, but it typically takes 2-3 months.",
  "See the list above for the required documents.",
  "Check the official website for the latest online application options.",
];

const BiharRegistration = () => {
  return (
    <div style={{ background: "black" }}>
      <div style={{ height: "5%" }}></div>
      <Container>
        <Helmet>
          <title>Permanent Medical Registration Bihar: Step-by-Step Guide</title>
          <meta
            name="description"
            content="Learn how to apply for permanent medical registration in Bihar. Get detailed steps, required documents, and official links. Simplify your Bihar medical council registration."
          />
          <meta
            name="keywords"
            content="permanent medical registration Bihar, Bihar medical council registration, medical registration in Bihar, how to register as a doctor in Bihar, doctors registration Bihar, Bihar medical license"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-permanent-registration-bihar" />
        </Helmet>

        <Main>
          <Section>
            <H1>Permanent Medical Registration Bihar: A Comprehensive Guide</H1>

            <H2>Why Permanent Medical Registration in Bihar is Essential?</H2>
            <P>
              If you want to practice medicine in Bihar, you need to get registered with the Bihar Medical Council (BMC). Think of it like getting a license to drive – it's your official permission to work as a doctor here.
            </P>

            <H2>What Happens if You Don't Register?</H2>
            <P>
              Without registration, you cannot legally practice medicine in Bihar. You might face legal consequences, and it could impact your career and credibility.
            </P>

            <H2>Who Needs to Register?</H2>
            <P>
              All medical graduates (MBBS, MD, MS, DNB, Diploma, DM, MCh) planning to practice in Bihar must register with the Bihar Medical Council. This includes fresh graduates, doctors moving from other states, and foreign medical graduates.
            </P>

            <H2>How to Apply for Bihar Medical Council Registration: A Step-by-Step Guide</H2>

            <H3>1. Gather Required Documents</H3>
            <UL>
              <LI>Medical degree certificate (MBBS/MD/MS).</LI>
              <LI>Internship completion certificate.</LI>
              <LI>Proof of identity (Aadhaar, PAN, Passport).</LI>
              <LI>Proof of address.</LI>
              <LI>Passport-sized photographs.</LI>
              <LI>Medical college registration certificate.</LI>
              <LI>Registration fee payment receipt.</LI>
            </UL>

            <H3>2. Contact the Bihar Medical Council Directly</H3>
            <P>
              Always reach out to the BMC to confirm the latest registration guidelines, required documents, and fee structure.
            </P>

            <H3>3. Get and Fill the Application Form</H3>
            <P>
              Obtain the registration form from the Bihar Medical Council website or office, fill it accurately, and attach the required documents.
            </P>

            <H3>4. Submit the Application and Pay Fees</H3>
            <P>
              Submit your completed form at the BMC office and pay the applicable registration fee.
            </P>

            <H3>5. Verification Process</H3>
            <P>
              The council will review and verify your application and documents.
            </P>

            <H3>6. Receive Your Registration Certificate</H3>
            <P>
              Once verified, you will be issued your permanent registration certificate.
            </P>

            <H2>Important Links and Resources</H2>
            <UL>
              <LI><A href="[Official BMC Website]" target="_blank">Official Bihar Medical Council Website</A></LI>
              <LI><A href="[Link to registration form]" target="_blank">Download Registration Form</A></LI>
              <LI><A href="[Link to fee structure]" target="_blank">Fee Structure</A></LI>
              <LI>Bihar Medical Council Address: [Insert Address]</LI>
              <LI>Bihar Medical Council Contact: [Insert Contact Info]</LI>
              <LI>Bihar Medical Council Email: [Insert Email]</LI>
            </UL>
          </Section>
        </Main>
      </Container>

      <FAQFunction questions={questions} answers={answers} />

      <div style={{marginLeft:"5%", marginRight:"5%", marginTop:"5%"}}>
      <p style={{fontStyle:"italic", font:"11px", color:"lightgray"}}>
      Disclaimer:
      </p>
      <p style={{fontStyle:"italic", font:"9px", color:"lightgray"}}>
    The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.</p>
   
    </div>
   
      <FooterNext />
    </div>
  );
};

// Styled Components
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top: 45px;
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 100;
`;

const H2 = styled.h2`
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 200;
  color: #ff007f;
`;

const H3 = styled.h3`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 35px;
  color: #FFFF00;
`;

const P = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 35px;
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 15px;
`;

const LI = styled.li`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 8px;
`;

const A = styled.a`
  color: #007bff;
  text-decoration: none;
`;

export default BiharRegistration;
