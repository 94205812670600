import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';

const questions = [
  "How long does the registration process take?",
  "What documents are required for registration?",
  "Can I apply online?",
];

const answers = [
  "The processing time varies, but it typically takes a few weeks to a couple of months.",
  "See the list above for the required documents.",
  "Yes, the application process is primarily online through the DMC portal.",
];

const DelhiMedicalCouncilPermanentRegistration = () => {
  return (
    <div style={{ background: "black" }}>
      <div style={{ height: "5%" }}></div>
      <Container>
        <Helmet>
          <title>Delhi Medical Council Registration: A Step-by-Step Guide</title>
          <meta
            name="description"
            content="Learn how to register with the Delhi Medical Council (DMC). Get a detailed guide, eligibility criteria, required documents, and online application process."
          />
          <meta
            name="keywords"
            content="Delhi Medical Council registration, DMC registration, medical registration in Delhi, how to register as a doctor in Delhi, doctors registration Delhi, Delhi medical license"
          />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-permanent-registration-delhi" />
        </Helmet>

        <Main>
          <Section>
            <H1>Delhi Medical Council Registration: A Step-by-Step Guide</H1>

            <H2 style={{color: "#ff007f", marginTop:"5%" }}>Why Permanent Medical Registration in Delhi is Essential?</H2>
          <P>
            Permanent registration with the Delhi Medical Council is mandatory for all medical practitioners in the state. It validates your qualifications and ensures you can legally practice medicine. This is crucial for maintaining professional integrity and providing quality healthcare services to the public.
          </P>

          <H2 style={{color: "#ff007f", marginTop:"5%"  }}>What are the Consequences of Not Registering?</H2>
          <P>
            The primary reason for obtaining permanent medical registration is to legally practice medicine in Delhi. Without it, you are not authorized to practice. Practicing medicine without registration can lead to legal penalties, including fines and potential legal action. Furthermore, it undermines the trust and confidence of patients and the medical community.
          </P>

          <H2 style={{color: "#ff007f", marginTop:"5%"  }}>Who Needs to Apply for Permanent Medical Registration?</H2>
          <P>
            All medical graduates (MBBS, MD, MS, DNB, Diploma, DM, McH) who intend to practice medicine in Delhi must apply for permanent registration with the Delhi Medical Council. This includes newly graduated doctors, those relocating to Delhi from other states, and foreign medical graduates who have obtained necessary clearances.
          </P>

            <H2 style={{ color: "#ff007f", marginTop: "5%" }}>Required Documents</H2>
            <UL style={{ marginBottom: "5%" }}>
              <LI>Copy of Medical Degree (MBBS or equivalent)</LI>
              <LI>Internship Completion Certificate (if applicable)</LI>
              <LI>Marksheet of MBBS (or equivalent course)</LI>
              <LI>Provisional or Permanent Medical Registration Certificate from MCI or respective State Medical Council</LI>
              <LI>Photographs (Passport-size)</LI>
              <LI>Identity Proof (Aadhar Card, Passport, or Voter ID)</LI>
              <LI>Proof of Address (Aadhar Card, Utility Bill, etc.)</LI>
              <LI>MCI Registration or Certificate of Medical Registration from other States (if applicable)</LI>
            </UL>

            <H2 style={{ color: "#ff007f" }}>Registration Process</H2>

            <H3 style={{ color: "#FFFF00" }}>1. Visit the Official DMC Website</H3>
            <P style={{ marginBottom: "5%" }}>Go to the official Delhi Medical Council website. You can find all the necessary details and forms here.</P>

            <H3 style={{ color: "#FFFF00" }}>2. Create an Account on the Portal</H3>
            <P style={{ marginBottom: "5%" }}>
              On the website, look for the "Registration" section. Create a new account by entering your personal details like name, email address, phone number, etc. Once your account is created, you will receive a verification email. Click on the link to verify your account.
            </P>

            <H3 style={{ color: "#FFFF00" }}>3. Fill Out the Registration Form</H3>
            <P style={{ marginBottom: "5%" }}>
              After logging in, fill out the Online Registration Form. Make sure to double-check the form for accuracy before submission. Enter your educational qualifications, internship details, and previous medical registrations, if applicable.
            </P>

            <H3 style={{ color: "#FFFF00" }}>4. Upload Documents</H3>
            <P style={{ marginBottom: "5%" }}>
              Upload scanned copies of the documents required (as listed above). Ensure that the document formats and sizes match the portal’s requirements.
            </P>

            <H3 style={{ color: "#FFFF00" }}>5. Pay the Registration Fee</H3>
            <P style={{ marginBottom: "5%" }}>
              The Delhi Medical Council charges a registration fee for processing your application. This fee can vary depending on the type of registration (e.g., provisional or permanent). Pay the fee online through the available payment methods (credit/debit card, net banking, etc.).
            </P>

            <H3 style={{ color: "#FFFF00" }}>6. Submit the Application</H3>
            <P style={{ marginBottom: "5%" }}>
              After completing all the sections of the form and uploading the necessary documents, submit your application for review. Ensure that all information is correct before final submission.
            </P>

            <H3 style={{ color: "#FFFF00" }}>7. Wait for Approval</H3>
            <P style={{ marginBottom: "5%" }}>
              Once the application is submitted, it will be reviewed by the DMC authorities. If all the details and documents are in order, your application will be processed, and you will receive your registration details. This process may take a few days to a couple of weeks depending on the workload.
            </P>

            <H3 style={{ color: "#FFFF00" }}>8. Receive Your Registration Certificate</H3>
            <P style={{ marginBottom: "5%" }}>
              After approval, you will receive your Delhi Medical Council Registration Certificate either in physical form or through email (as per DMC’s process). The certificate is essential for you to practice medicine legally in Delhi.
            </P>

            <H3 style={{ color: "#FFFF00" }}>9. Verification and Renewal</H3>
            <P style={{ marginBottom: "5%" }}>
              The registration with the Delhi Medical Council is valid for a specific period and needs to be renewed. Make sure to keep track of renewal deadlines and requirements to continue practicing.
            </P>

            <H2 style={{ color: "#ff007f", fontWeight: "bold" }}>Important Links and Resources</H2>
<UL>
  <LI><A href="https://dmc.delhi.gov.in/" target="_blank" rel="noopener noreferrer">Official Delhi Medical Council Website</A></LI>
  <LI><A href="https://dmc.delhi.gov.in/registration-form/" target="_blank" rel="noopener noreferrer">Download Registration Form</A></LI>
  <LI><A href="https://dmc.delhi.gov.in/fee-structure/" target="_blank" rel="noopener noreferrer">Fee Structure</A></LI>
  <LI>Delhi Medical Council Address: Ground Floor, B Wing, B Block-1, DMRC IT Park, Shastri Park, New Delhi-110053</LI>
  <LI>Delhi Medical Council Working Days: Monday to Friday</LI>
  <LI>Delhi Medical Council Office Hours: 9.00 AM to 5.30 PM</LI>
  <LI>Delhi Medical Council Public Dealing Hours: 9.30 AM to 4.30 PM</LI>
  <LI>Delhi Medical Council Lunch Break: 1.30 PM to 2.00 PM</LI>
  </UL>
          </Section>
        </Main>
      </Container>

      <FAQFunction questions={questions} answers={answers} />

      <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "5%" }}>
        <p style={{ fontStyle: "italic", font: "11px", color: "lightgray" }}>
          Disclaimer:
        </p>
        <p style={{ fontStyle: "italic", font: "9px", color: "lightgray" }}>
          The information provided in this article is for general informational purposes only. While we strive to keep the content accurate and up to date, we make no guarantees about the completeness, reliability, or accuracy of the information. This article does not constitute legal, financial, or professional advice. Readers are encouraged to verify details with the relevant authorities before making any decisions. The company assumes no responsibility for any errors, omissions, or consequences arising from the use of this information.
        </p>
      </div>
      <FooterNext />
    </div>
  );
};

// Styled Components (Same as before)
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top: 45px;
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 100;
  margin-top: 45px;
`;

const H2 = styled.h2`
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 200;
`;

const H3 = styled.h3`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 35px;
`;

const P = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 35px;
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 15px;
`;

const LI = styled.li`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 8px;
`;

const A = styled.a`
  color: #007bff;
  text-decoration: none;
`;

export default DelhiMedicalCouncilPermanentRegistration;