import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import firebase from "firebase";
import { db } from "../firebaseConfig";
import "../index.css";
import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";
import ShowColumn from "../components/showColumn";
import HorizontalScrollSlider from "../components/horizontalScroll";
import GetApp from "../Footers/getApp";
import FooterNew from "../Footers/Footer";
import Todo from "../components/base/Todo";
import Next from "../components/showBranchImages";
import ShowProducts from "../components/showProducts";
import Slideshow from "../medicalstudent/slideshow_medicalstudent";
import ShowBranches from "../components/showBranches";
import ShowExperts from "../components/showExperts";
import ShowMemes from "../components/showMemes";
import FooterNext from "../Footers/FooterNext";
import { Alert } from "react-bootstrap";

import doctorpic from "./images/doctorpic.png"
import personalAdvisor from "../club/images/personalmarketingadvisor.png";
import onCallsImage from "../club/images/on_calls.png";
import jobImage from "../club/images/findjobs.png";
import airportImage from "../club/images/airports.png";
import eventsImage from "../club/images/eventsconferences.png";
import golfImage from "../club/images/golfgames.png";
import trainingImage from "../club/images/trainingcourses.png";
import observershipsImage from "../club/images/observerships.png";
import BecomeAMember from "../club/membershipInstructions";
import FAQFunction from "../blog/informative articles/faq_segment/faqFunction";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

function Doctor() {
  const [startDate, setStartDate] = useState(new Date().toDateString());


  const [todoList, setTodoList] = useState();
  const [nextList, setNextList] = useState();

  useEffect(() => {
    const todoRef = firebase
      .database()
      .ref("todo");
    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setTodoList(todoList);
    });

    const showImageRef = firebase.database().ref("next");
    showImageRef.on("value", (snapshot) => {
      const n = snapshot.val();
      const nextList = [];
      for (let id in n) {
        nextList.push({ id, ...n[id] });
      }
      setNextList(nextList);
    });

  }, []);



  const [post, setPosts] = useState([]);
  const [postsX, setPostsX] = useState([]);
  db.collection("slideshowschool").onSnapshot((snapshot) => {
    setPostsX(snapshot.docs.map((doc) => ({ id: doc.id, postsX: doc.data() })));
  });

  return (
    <div>

      {/*<div class="container" style={{ height: "400px", width:"500px" }}>
        <Slideshow path="slideshow/doctor/get" />
      </div>

      <div>
        <h5>Hello Doctor </h5>
      </div>*/}

      {/*<div style={{ display: "flex", justifyContent: "space-between", height: "700px", width: "100%" }}>
  <div className="container" style={{ height: "700px", width: "700px", marginLeft: "45px" }}>
    <Slideshow path="slideshow/doctor/get" />
  </div>

  
</div> */}


      {/*<div className="showImg">
      <HorizontalScrollSlider path="showtype" />
      </div>*/}

      <div class="container" style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Alert variant="success"><p><b>
          Please note that Dr Planete is merely a platform where organizations (Hospitals, Clinics, Schools/Educational Institutes, Industries & Corporates etc.) can post request when they require services of a doctor.
          Dr Planete does not provide or claim to provide any kind of employment.
        </b> </p></Alert>
      </div>
      {/*<div style={{ marginTop: "35px" }}>
        <div class="container body-content">
          <div className="columns-2">
            {postsX.map(({ id, postsX }) => {
              return (
                <ShowColumn
                  key={id}
                  slideshow_image={postsX.slideshow_image}
                  onClickfunction={postsX.onClickfunction}
                />
              );
            })}
          </div>

        </div>
          </div>*/}
      {/*<div style={{ marginTop: "35px", marginBottom: "35px" }}>
        <ShowMemes />
      </div>*/}



      {/*<div>//working realtime database fetch
      {todoList
        ? todoList.map((todo, index) => <Todo todo={todo} key={index} />)
        : ''}
      </div>*/}

      {/*<div>//next working realtime database fetch
      {nextList
        ? nextList.map((next, index) => <Next next={next} key={index} />)
        : ''}
      </div>*/}

      {/*<ShowBranches specialization="nice" />*/}
      <FooterNext />
    </div>
  );
}

function DoctorView() {

  return (
    <div style={{ marginTop: "5%" }}>

      <div style={{ marginLeft: "15%", marginRight: "15%", marginBottom:"15%" }}>

        <div>
          <h1 style={{ fontSize: "75px" }}>Hello Doctor</h1>
          <p style={{ marginTop: "-2%", marginLeft: "0.5%", color: "lightslategray" }}>Glad to have you here</p>
        </div>


        <div style={{ marginTop: "5%" }}>
          <h4 style={{ color: "blue", fontWeight: "bold" }}>Welcome to Dr Planete</h4>
          <h5 style={{ color: "orangered", fontWeight: "bold", marginTop: "-1%" }}>The Premier Club - Only for Doctors, Hospitals & Medical Students in India</h5>
        </div>

        <div style={{ marginTop: "5%" }}>
          <h4 style={{ color: "purple", fontWeight: "bold" }}>5 Reasons to become Dr Planete Doctor Member</h4>
        </div>


        <div>
          <p><b>Access to Events and Conferences </b> <p style={{ color: "lightslategray", fontWeight: "light", marginTop: "0%" }}>Organized and hosted by Dr Planete</p>
          </p>
        </div>

        <div>
          <p><b>Services of a Personal Marketing Advisor</b> <p style={{ color: "lightslategray", fontWeight: "light", marginTop: "0%" }}>for your practice offering In-Person Guidance to enhance your personal brand</p></p>
        </div>

        <div>
          <p><b>Find Latest Jobs PAN India</b></p>
        </div>

        <div>
          <p><b>Apply for On-Call Opportunities at hospitals</b></p>
        </div>

        <div>
          <p><b>View Requests for Visiting Doctor from Schools & Industrial organizations</b></p>
        </div>

        <div>
          <p><b>Access to Domestic Airport Lounge in India</b> <p style={{ color: "lightslategray", fontWeight: "light", marginTop: "0%" }}>Only for Pro Doctor Members (Terms & Conditons Apply)</p></p>
        </div>
      </div>

      <FooterNext />
    </div>
  )
}
//export default DoctorView;

const questions = [
  "Who can become a member of Dr Planete?",
  "What are the different types of doctor memberships at Dr Planete?",
  "Difference between Pro Doctor Membership and Basic Lifetime Membership",
];

const answers = [
  "Only doctors, hospitals and medical students registered in India can become a member of Dr Planete",
  "Dr Planete's Doctor Membership comes in two tiers - Basic Lifetime Membership and Pro Doctor Membership",
  "Pro Doctor Membership includes access to Airport Lounge Access, Golf Games and lessons, Spa facilities, exclusive offers and much more.",
];

const DoctorBanner = () => {
  return (
    <div style={{ background: "black" }}>
      <div className="container">
        <div className="image">
          <img src={doctorpic} alt="Doctor" />
        </div>
        <div className="content">
          <h2>
            If <span className="bold">Life</span> is missing from your work-life balance,
            <br /> you <span className="bold">must be a doctor</span>
          </h2>
        </div>
      </div>

      <div className="growBenefitsContainer">
      <style>
        {`
          .growBenefitsContainer {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .growBenefitsTitle {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
            font-family: 'Poppins', sans-serif;
          }

          .growBenefitsTitle span {
            color: #ff007f; /* Pink */
          }

          .growBenefitsGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            max-width: 1200px; /* Increased width for the grid */
            margin: 0 auto;
          }

          .growBenefitsCard {
            background-color: #333;
            border-radius: 30px;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            animation: fadeIn 1.5s ease-in-out;
            height: 200px; /* Maintain consistent height */
            width: 400px; /* Increased horizontal length */
            overflow: hidden; /* Prevent content overflow */
          }

          .growBenefitsCard img {
            width: 60px; /* Adjusted size for better proportions */
            height: auto;
            background-color: #333;
          }

          .growBenefitsCardText {
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 1rem;
            font-family: "antic";
            color: white;
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflowed text */
            text-overflow: ellipsis; /* Adds ellipsis for overflowed text */
            width: 100%;
            text-align: center;
            background-color: #333;
          }

          .growBenefitsDivider {
            margin: 2rem auto;
            width: 80%;
            height: 2px;
            background-color: #ff007f; /* Pink */
          }

          /* Animations */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /* Mobile Responsiveness */
          @media (max-width: 768px) {
            .growBenefitsTitle {
              font-size: 2rem;
            }

            .growBenefitsGrid {
              grid-template-columns: 1fr; /* Stack cards vertically on small screens */
              max-width: 90%; /* Ensure cards occupy 90% of the screen width */
            }

            .growBenefitsCard {
              width: 100%; /* Make sure the card takes up the full width */
              height: 160px; /* Adjust height for smaller screens */
            }

            .growBenefitsCard img {
              width: 50px; /* Slightly smaller images for mobile */
            }

            .growBenefitsCardText {
              font-size: 1.3rem; /* Adjust text size for readability */
            }
          }

          @media (max-width: 480px) {
            .growBenefitsTitle {
              font-size: 1.8rem;
              margin-bottom: 1.5rem;
            }

            .growBenefitsCard {
              padding: 1rem;
              height: 140px; /* Further reduce height */
            }

            .growBenefitsCard img {
              width: 40px; /* Further reduce image size */
            }

            .growBenefitsCardText {
              font-size: 1.1rem; /* Smaller text size */
            }
          }
        `}
      </style>
      <h1 className="growBenefitsTitle">
        For Work and Life balance <span>Dr Planete</span>
      </h1>
      <div className="growBenefitsGrid">
        <div className="growBenefitsCard">
          <img src={jobImage} alt="Health & Fitness" />
          <span className="growBenefitsCardText">Find Jobs across India</span>
        </div>
        <div className="growBenefitsCard">
          <img src={airportImage} alt="Nutrition & Diet" />
          <span className="growBenefitsCardText">Airport Lounge Access</span>
        </div>
        <div className="growBenefitsCard">
          <img src={onCallsImage} alt="Nutrition & Diet" />
          <span className="growBenefitsCardText">Find On-Call Opportunities</span>
        </div>
        <div className="growBenefitsCard">
          <img src={personalAdvisor} alt="Wellness Programs" />
          <span className="growBenefitsCardText">Personal Marketing Advisor</span>
        </div>
        
        <div className="growBenefitsCard">
          <img src={eventsImage} alt="Wellness Programs" />
          <span className="growBenefitsCardText">Events & Conferences</span>
        </div>
        <div className="growBenefitsCard">
          <img src={golfImage} alt="Nutrition & Diet" />
          <span className="growBenefitsCardText">Golf Games and Lessons</span>
        </div>
        <div className="growBenefitsCard">
          <img src={trainingImage} alt="Nutrition & Diet" />
          <span className="growBenefitsCardText">Training Courses</span>
        </div>
        
        <div className="growBenefitsCard">
          <img src={observershipsImage} alt="Nutrition & Diet" />
          <span className="growBenefitsCardText">Observerships</span>
        </div>
      </div>
    </div>

    

    <BecomeAMember/>

    <FAQFunction questions={questions} answers={answers}/>
    
      <FooterNext/>
    </div>
  );
};

export default DoctorBanner;

// CSS
const styles = `
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    color: white;
    padding: 0; 
    margin: 0;
    flex-wrap: wrap;
    font-family: 'Poppins', sans-serif;
  }

  .content {
    max-width: 75%;
    text-align: left;
  }

  h2 {
    font-size: 35px;
    line-height: 1.2;
    font-weight:100;
  }

  .highlight {
    color: #ff007f;
    font-weight: bold;
  }

  .bold {
    color: #ff007f;
    font-weight: 300;
    font-size: 50px; /* Matched h2 font-size */
  }

  .image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    width: 20%;
    
  }

  .image img {
    width: 100%;
    border-radius: 10px;
    
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      text-align: center;
      padding: 25px;
    }
    .content {
      max-width: 100%;
      text-align: left;
    }
    .image {
      justify-content: center;
      width: 80%;
    }
    h2 {
      font-size: 45px;
    }
    .bold {
      font-size: 45px; /* Matched h2 font-size for mobile */
    }
    .image img {
      width: 350px;
    }
  }
`;

const styleTag = document.createElement("style");
styleTag.innerHTML = styles;
document.head.appendChild(styleTag);