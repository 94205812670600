import React from "react";
import { Link } from "react-router-dom";
import "./FooterNext.css";
import { FooterLink } from "./FooterStyles";

export default function FooterNext({ }) {



    return (
        <div className="footer">

            <div className="sb__footer section__padding">
                <hr style={{ color: "white" }}></hr>
                <div className="sb__footer-links">
                    <div className="sb__footer-links-div">
                        <h4 style={{ color: " #ff007f", marginTop: "5%", marginBottom: "10%" }}>Schools/Corporate</h4>
                        <a href="safeplan">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Request Visiting Doctor
                            </p>
                        </a>
                        <a href="safeplan">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Employee Health Checkup Plans
                            </p>
                        </a>
                        <a href="safeplan">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Student Health Checkup Plans
                            </p>
                        </a>
                        <a href="safeplan">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Vaccination Plans
                            </p>
                        </a>
                        <a href="safeplan">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Educational Sessions
                            </p>
                        </a>
                    </div>

                    <div className="sb__footer-links-div">
                        <h4 style={{ color: " #ff007f", marginTop: "5%", marginBottom: "10%" }}>Medical Students</h4>
                        <a href="campus-ambassador-program">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Campus Ambassador Program
                            </p>
                        </a>
                        <a href="creator-connect">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Creator Connect
                            </p>
                        </a>
                        <a href="affiliate-connect">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Affiliate Connect
                            </p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4 style={{ color: " #ff007f", marginTop: "8%", marginBottom: "15%" }}>Doctors</h4>
                        <a href="doctor">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Doctor Membership
                            </p>
                        </a>
                        <a href="educator-registration">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Educators
                            </p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4 style={{ color: " #ff007f", marginTop: "5%", marginBottom: "15%" }}>Hospitals</h4>
                        <a href="hospital-membership">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                Hospital Membership
                            </p>
                        </a>
                        <a href="list-training-course">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                List Training Course
                            </p>
                        </a>
                        <a href="list-observership-plan">
                            <p style={{ color: "white", textDecoration: "underline", textDecorationColor: "black", fontSize: "17px" }}>
                                List Observership Plan
                            </p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4 style={{ color: " #ff007f", marginTop: "2%", marginBottom: "10%", marginLeft: "5%" }}>Follow us on Social Media</h4>
                        <div className="socialmedia">
                            <FooterLink target="_blank" href="https://www.facebook.com/DrPlanete">
                                <i style={{ color: "white", marginRight: "15px", marginLeft: "15px" }} className="fab fa-facebook-f fa-2x" />
                            </FooterLink>
                            <FooterLink target="_blank" href="https://www.instagram.com/drplanete/">
                                <i style={{ color: "white", marginRight: "15px" }} className="fab fa-instagram fa-2x" />
                            </FooterLink>
                            <FooterLink target="_blank" href="https://www.youtube.com/@drplanete4495">
                                <i style={{ color: "white", marginRight: "15px" }} className="fab fa-twitter fa-2x" />
                            </FooterLink>

                            <FooterLink target="_blank" href="https://www.linkedin.com/company/dr-planete/">
                                <i style={{ color: "white", marginRight: "35px" }} className="fab fa-linkedin fa-2x" />
                            </FooterLink>
                        </div>

                        <div>

                            <a href="https://apps.apple.com/in/app/dr-planete/id6473446013">

                                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83" alt="Download on the App Store" style={{ width: "80%", height: "auto", marginTop: "7%", marginBottom: "5%", marginLeft: "20px" }} /></a>

                            <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img
                                    style={{ width: "90%", height: "120px", marginTop: "0%", marginLeft: "2%" }}

                                    class="google_play"
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"

                                />
                            </a>
                        </div>

                    </div>
                    <div>

                    </div>
                </div>

                <hr style={{ color: "white" }}></hr>

                <div>
                    <h4 style={{ color: "white" }}>Popular <span style={{ color: "#ff007f" }}>Searches</span></h4>

                    <p style={{ color: "white" }}>Doctor jobs Delhi | Doctor jobs Mumbai | Doctor jobs Hyderabad | Doctor jobs Chennai | Doctor jobs Jaipur | Doctor jobs Indore | Doctor jobs Bhopal | Doctor jobs Pune | Doctor jobs Gurugram | Doctor jobs Shimla </p>

                    <div className="registrationInfo">
                        <p style={{ color: "white", marginRight:"10px" }}>How to apply for provisional registration after passing MBBS?</p>
                        <p style={{ color: "white" }}>How to apply for permanent registration after passing MBBS ?</p>
                        <p style={{ color: "white" }}>How to apply for additional qualification registration ?</p>
                        <p style={{ color: "white" }}>How to apply for registration renewal ? </p>
                        <p style={{ color: "white" }}>How to apply for No Objection certificate?</p>
                    </div>

                </div>
                <hr style={{ color: "white" }}></hr>

                <div className="sb__footer-below">
                    <div className="sb__footer-copyright">

                        <p>
                            <i className="far fa-copyright">{"  "}</i>
                            2023 Sungowin Technologies Pvt. Ltd. All rights reserved.
                        </p>

                        <p>CIN: U72900RJ2022PTC079589</p>
                        <p>GSTIN: 08ABHCS8438B1Z7 </p>
                        <p>Registered Address: 54-55 Arun Vihar Niwaroo Road Jaipur - 302012</p>
                        <p>Phone: +91 8619460759</p>
                    </div>
                    <div className="sb__footer-below-links">

                        <a href="contact">
                            <div>
                                <p>Contact</p>
                            </div>
                        </a>

                        <a href="careers">
                            <div>
                                <p>Careers</p>
                            </div>
                        </a>

                        <a href="termsandconditions">
                            <div>
                                <p>Terms & Conditions</p>
                            </div>
                        </a>
                        <a href="privacypolicy">
                            <div>
                                <p>Privacy Policy</p>
                            </div>
                        </a>
                        <a href="#">
                            <div>
                                <p>FAQ</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}