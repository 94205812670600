import React from "react";
import toxicseniorimg from "../images/toxicsenior.png";

import docgamesimg from "../images/docgames.png";

const FunGamesSection = () => {
  return (
    <div className="game-container">
      <style>
        {`
          /* Main Container */
          .game-container {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            padding: 50px;
            font-family: 'Poppins', sans-serif;
          }

          /* Left Text Section */
          .game-text-container {
            width: 50%;
          }

          /* Heading */
          .game-heading {
            font-size: 2.8rem;
            font-weight: 700;
            margin-bottom: 15px;
          }

          .game-highlight {
            color: #ff0080; /* Pink */
          }

          /* Bullet Points */
          .game-list {
            font-size: 1.2rem;
            line-height: 1.8;
          }

          /* Right Image */
          .game-image {
            width: 50%;
            max-width: 600px;
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .game-container {
              flex-direction: column-reverse;
              padding: 20px;
            }

            .game-text-container {
              width: 100%;
            }

            .game-image {
              width: 100%;
              max-width: 350px;
            }

            .game-heading {
              font-size: 2rem;
              text-align: center;
            }

            .game-list {
              font-size: 1rem;
               text-align: left;
            }
          }
        `}
      </style>

      {/* Left Text Section */}
      <div className="game-text-container">
        <h1 className="game-heading">
          Exciting fun filled <span className="game-highlight">games</span>
        </h1>

        <ul className="game-list">
        <li>
MBBS life sirf books, exams aur hospital rounds tak seemit nahi hona chahiye! Dr Planete lekar aaya hai fun-filled games & challenges sirf medical students ke liye – taaki thoda stress hatao aur doston ke saath maze udao! 🎯🎮  
</li>

<br></br>
<li>
<b>📢 Padhai ke saath thodi masti bhi zaroori hai!  </b></li>
<li>
  <br></br>
🔹Dr Planete member bano aur MBBS life ko <b>fun & entertaining</b> banao! 🚀🔥</li>  
        </ul>
      </div>

      {/* Right Image */}
      <img 
        src={docgamesimg}
        alt="Doctors playing fun games" 
        className="game-image" 
      />
    </div>
  );
};

export default FunGamesSection;
