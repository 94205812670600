import React from "react";

import deliveryptimg from "../images/deliveryptad.png";


const DeliveryPatientAd = () => {
  return (
    <div>
    <div className="deliverypt" style={{background:"black"}}>
      <img src={deliveryptimg} alt="Pregnant Woman" className="deliveryimagept" />
      <div className="text-container" style={{marginLeft:"-22%"}}>
        <h2 className="heading">Delivery Patient Hai?</h2>
        <h3 className="subheading">Post Call on Dr Planete</h3>
        <ul className="list">
          <li>Gynecologist</li>
          <li>Pediatrician</li>
          <li>Anesthetist</li>
        </ul>

        <p className="adtext">Dr Planete helps you find on-call and locum doctors in your city, making it easier than ever to connect with on-call and locum doctors.
Whether you're looking for a gynecologist, resident doctor, anesthetist, or pediatrician, you're just clicks away from posting your requirements.
Post your hospital’s requirements, and interested doctors can connect at their discretion—quick, seamless, and hassle-free.</p>
      </div>
      
    </div>

    <div className="disclaimerct">
        <p style={{color:"white", marginLeft:"1%", marginRight:"1%"}}><b>Disclaimer:  

Dr Planete does not provide or claim to provide doctors to any hospital</b> Dr Planete simply allows hospitals to post their requests, which will be visible to the respective specialists (gynecologists, pediatricians, anesthetists) who are members of Dr Planete. Interested doctors may contact the hospital directly at their discretion. <b>Dr Planete does not guarantee doctor availability, response time, or the quality of service provided by any doctor.</b> The hospital and the engaged doctor(s) are solely responsible for their professional arrangements. Dr Planete will not be liable for any consequences arising from such engagements.</p>
      </div>
    </div>
  );
};

export default DeliveryPatientAd;

const styles = `
  .deliverypt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 0;
    color: white;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    margin:0;
  }
  
  .deliveryimagept {
    width: 60%;
    height: 100vh;
    object-fit: cover;
    background:black;
    margin:0;
  }
  
  .text-container {
    width: 70%;
    text-align: left;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background:black;
    margin-top:-10%;
  }
  
  .heading {
    font-size: 85px;
    font-weight: bold;
  }
  
  .subheading {
    font-size: 60px;
    font-weight: bold;
    color:#ff007f;
  }
  
  .list {
    list-style-type: none;
    
    font-size: 35px;
  }
  
  .list li {
    margin-bottom: 10px;
  }
  
  .disclaimerct{
  font-size:15px;
  text-color:white;
  }

  .adtext{ 
  font-size:default;
  }
  @media (max-width: 768px) {
    .deliverypt{
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: 0px;
    }
    .deliveryimagept {
      width: 62%;
      height: auto;
    }
    .text-container {
      width: 60%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top:10%;
    }

     .heading {
    font-size: 25px;
    font-weight: bold;
  }
  
  .subheading {
    font-size: 15px;
    font-weight: bold;
    color: #ff4081;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
    font-size: 20px;
  }
  
  .list li {
    margin-bottom: 10px;
  }

  .disclaimerct{
  font-size:15px;
  text-color:white;
  }

  .adtext{
  font-size:0px;
  }
  }
`;

const styleElement = document.createElement("style");
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);
