import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import FooterNext from '../../../../Footers/FooterNext';
import FAQFunction from '../../faq_segment/faqFunction';
import InformativeArticlesDisclaimer from '../../informativeArticlesDisclaimer';

const questions = [
    "How long does the registration process take?",
    "What documents are required for registration?",
    "Can I apply online?",
  ];
  
const answers = [
    "The processing time varies, but it typically takes 2-3 months",
    "See the list above for the required documents.",
    "Check the official website for the latest online application options.",
  ];
  
const GoaMedicalRegistration = () => {
  return (
    <div style={{background:"black"}}>
      <div style={{ height: "5%" }}></div>
    <Container>
      <Helmet>
        <title>Goa Medical Council Registration: Step-by-Step Guide</title>
        <meta
          name="description"
          content="Learn how to apply for permanent medical registration in Goa. Get detailed steps, required documents, and official links."
        />
        <meta
          name="keywords"
          content="Goa medical council registration, medical registration in Goa, how to register as a doctor in Goa, doctors registration Goa, Goa medical license"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.drplanete.com/how-to-apply-for-goa-medical-registration" />
      </Helmet>

      <Main>
        <Section>
          <H1>Goa Medical Council Registration: A Comprehensive Guide</H1>

          <H2 style={{color: "#ff007f"}}>Why GMC Registration is Important?</H2>
          <P>
            Registration with the Goa Medical Council is legally required for practicing medicine in Goa. It ensures credibility, legal compliance, and eligibility for employment in hospitals and clinics across the state.
          </P>

          <H2 style={{color: "#ff007f"}}>What Happens if You Don't Register?</H2>
          <P>
            Practicing without registration is illegal and can result in legal action, reputational damage, and loss of job opportunities.
          </P>

          <H2 style={{color: "#ff007f"}}>Who Needs to Register?</H2>
          <P>
            Any doctor intending to practice medicine in Goa, irrespective of where they obtained their degree, must register with the GMC.
          </P>

          <H2 style={{color: "#ff007f"}}>How to Apply for GMC Registration: Step-by-Step Guide</H2>

          <H3 style={{color: "#FFFF00"}}>1. Visit the Goa Medical Council Website</H3>
          <P>Check the official GMC website for updated guidelines and application details.</P>

          <H3 style={{color: "#FFFF00"}}>2. Gather Required Documents</H3>
          <UL>
            <LI>MBBS/MD/MS degree certificate</LI>
            <LI>Internship completion certificate</LI>
            <LI>Proof of identity (Aadhaar, PAN, etc.)</LI>
            <LI>Proof of address</LI>
            <LI>Medical college registration certificate</LI>
            <LI>Passport-sized photographs</LI>
          </UL>

          <H3 style={{color: "#FFFF00"}}>3. Obtain and Complete the Application Form</H3>
          <P>Download or collect the form from GMC, fill it out accurately, and attach the necessary documents.</P>

          <H3 style={{color: "#FFFF00"}}>4. Submit the Application</H3>
          <P>Submit the application form with required documents to the GMC office.</P>

          <H3 style={{color: "#FFFF00"}}>5. Pay the Registration Fee</H3>
          <P>Pay the prescribed fee as per the latest GMC guidelines.</P>

          <H3 style={{color: "#FFFF00"}}>6. Verification and Approval</H3>
          <P>Your documents will be verified before approval.</P>

          <H3 style={{color: "#FFFF00"}}>7. Receive Your Registration Certificate</H3>
          <P>Upon successful verification, collect your GMC registration certificate.</P>

          <H2 style={{color: "#ff007f"}}>Important Notes</H2>
          <UL>
            <LI>Contact GMC directly for the latest updates.</LI>
            <LI>Keep copies of all submitted documents.</LI>
            <LI>Processing times vary, so apply well in advance.</LI>
            <LI>Visiting the GMC office in person can clarify doubts.</LI>
          </UL>
        </Section>
      </Main>
      </Container>

      <FAQFunction questions={questions} answers={answers}/> 

      <InformativeArticlesDisclaimer/>
   
      <FooterNext/>
    </div>
  );
};

// Styled Components
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  text-align: left;
  min-height: 100vh;
`;

const Main = styled.main`
  max-width: 85%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  margin-top: 45px;
`;

const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 100;
  margin-top: 45px;
`;

const H2 = styled.h2`
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 200;
`;

const H3 = styled.h3`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 35px;
`;

const P = styled.p`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 35px;
`;

const UL = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-bottom: 15px;
`;

const LI = styled.li`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 8px;
`;

export default GoaMedicalRegistration;
