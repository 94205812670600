import React from 'react';
import AppStoreIcon from './images/AppStoreIcon.png'; // Import your App Store icon
import GooglePlayIcon from './images/GooglePlayIcon.png'; // Import your Google Play icon

function BecomeAMember() {
  return (
    <div className="become-a-member-container">
      <style>
        {`
          .become-a-member-container {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .become-a-member-heading {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
          }

          .become-a-member-heading span {
            color: #ff007f; /* Pink */
          }

          .become-a-member-steps {
            list-style: none;
            padding: 0;
            margin-right: 2rem;
          }

          .become-a-member-steps li {
            margin-bottom: 1rem;
            font-size: 1.5rem;
          }

          .app-store-link, .google-play-link {
            margin: 0 10px;
            display: inline-block;
          }

          .app-store-icon, .google-play-icon {
            width: 90%;
            height: auto;
          }

          .member-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          }

          .text-column {
            flex: 1;
            max-width: 60%; /* 60% on larger screens */
            margin-left: 5%;
            
          }

          .icons-column {
            flex: 1;
            max-width: 30%; /* 30% for icons */
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          /* Mobile Responsiveness */
          @media (max-width: 768px) {
            .member-section {
              flex-direction: column;
            }

            .text-column {
              max-width: 100%; /* 100% on mobile */
              margin-bottom: 1rem;
            }

            .icons-column {
              max-width: 100%; /* 100% on mobile */
              align-items: left;
            }

            .app-store-icon, .google-play-icon {
              width: 80%;
              margin:auto, auto;
            }
          }
        `}
      </style>
      <h1 className="become-a-member-heading">
        How to  <span>Become a Member</span>
      </h1>
      <div className="member-section">
        <div className="text-column">
          <ul className="become-a-member-steps">
            <li>
              <b>Download</b> Dr Planete Mobile App from App Store (iOS) or Google Play Store (Android)
              
            </li>
            <li><b>Select</b> your Membership Type - Doctors, Hospitals or Medical Students</li>
            <li>
              <b>Enter</b> your complete details in the membership form, select membership tier and proceed to payment
            </li>
            <li><b>Payment</b> Options available - UPI, Credit Card, Debit Card, Internet Banking</li>
          </ul>
        </div>
        <div className="icons-column">
          <a href="https://apps.apple.com/in/app/dr-planete/id6473446013" className="app-store-link">
            <img className="app-store-icon" src={AppStoreIcon} alt="Download on App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp" className="google-play-link">
            <img className="google-play-icon" src={GooglePlayIcon} alt="Get it on Google Play" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BecomeAMember;
