import React from "react";

const MedStudentSectionTwo = () => {
  return (
    <div className="med-section-container">
      <style>
        {`
          .med-section-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: black;
            color: white;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            padding: 50px 20px;
            min-height: 50vh;
          }

          .med-text-line {
            font-size: 4.5rem;
            font-weight: 700;
            white-space: nowrap;
          }

          .med-highlight-pink {
            color: #ff3b77;
            font-weight: 800;
          }

          .med-highlight-orange {
            color: #ffa500;
            font-weight: 800;
          }

          .med-highlight-green {
            color: #03ff29;
            font-weight: 800;
          }

          .medsubdiv{
          margin-top:35px;
          margin-bottom:35px;
          }

          .med-subtext {
            font-size: 3rem;
            font-weight: 600;
            margin-top: 5px;
            margin-bottom:5px;
            opacity: 0;
            font-family: 'Poppins', sans-serif;
            animation: fadeIn 1s ease-in-out forwards;
          }

          .med-second-line {
            animation-delay: 2s;
          }

          .med-footer-text {
            font-size: 4rem;
            font-weight: 700;
            margin-top: 20px;
          }

          .med-footer-subtext {
            font-size: 1rem;
            color: gray;
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @media (max-width: 768px) {
            .med-text-line {
              font-size: 1.5rem;
            }
            .med-footer-text {
              font-size: 1.5rem;
            }
          }
        `}
      </style>

      <div className="med-text-line">We are your <span className="med-highlight-pink">gossip</span> buddy</div>
      <div className="medsubdiv">
      <div className="med-subtext">NEET-UG se lekar <span className="med-highlight-orange">NEET-PG</span> tak</div>
      <div className="med-subtext med-second-line">NEET-PG se lekar <span className="med-highlight-green">NEET-SS</span> tak</div>
      
      </div>
      <div className="med-footer-text">Padhai ke alawa kuch bhi puch lo</div>
      <div className="med-footer-subtext">(Ask us anything except studies, academics etc.)</div>
    </div>
  );
};

export default MedStudentSectionTwo;
